<template>
  <div v-if="!isDeleted" class="btn card mb-3 shadow">
    <div class="row d-flex align-items-center" @click="navigateToResult(id)">
      <div class="col text-white">
        {{ interpretability_name }}
      </div>
      <div class="col">
        Model: <span class="model-truncate" :title="model_used">{{ model_used }}</span>
      </div>
      <div class="col">
        Input: {{ type_of_input === '1' ? 'Text Field' : (type_of_input === '2' ? 'Text File' : 'Image') }}      </div>
      <div class="col" v-if="type_of_input === '1'">
        Analysis: {{ analysis_selected === '1' ? 'Sentiment' : 'Topic' }}
      </div>
      <div class="col" v-else>
      </div>
      <div class="col">
        Time Lapsed: {{ getTimeLapsed }}
      </div>
      <div class="col">
        Date: {{ this.getStartDate }}
      </div>
      <div class="col p-0">
        <div class="btn m-0">{{ this.status }}</div>
      </div>
      <div class="col-auto">
        <button class="btn me-4 my-auto text-white delete-icon" @click.stop="deleteInterpretability"
          title="Delete Interpretability Analysis">
          <i class="fa-regular fa-trash-can"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import "@/assets/css/AnonymizationStyles.css";


export default {
  name: "InterpretabilityItem",
  props: {
    id: { type: String, required: true },
    interpretability_name: { type: String, default: "" },
    model_used: { type: String, default: "" },
    status: { type: String, default: "" },
    date_start: { type: String, default: "" },
     date_end: { type: String, default: "" },
    type_of_input: { type: String, default: "" },
    analysis_selected: { type: String, default: "" }
  },
  data() {
    return {
      isDeleted: false,
    };
  },
  methods: {
    navigateToResult(id) {
      this.$router.push({ name: 'Interpretability result', params: { id: id } });
    },
    deleteInterpretability() {
      if (confirm("Are you sure you want to delete this interpretability analysis?")) {
        axios.delete("/analysis/history/delete/" + this.id)
          .then(() => {
            this.isDeleted = true;
          })
          .catch((error) => {
            console.error(error);
            this.errored = true;
          });
      }
    },
  },
  computed: {
    getStartDate() {
      return moment(this.date_start).format("DD/MM/YYYY HH:mm");
    },
    getTimeLapsed() {
    if (this.date_end !== null) {
      const stringDateStart = (String(this.date_start) + 'Z');
      const startDateTime = new Date(stringDateStart);
      const stringDateEnd = (String(this.date_end) + 'Z');
      const endDateTime = new Date(stringDateEnd);
      const diffMilliseconds = Math.abs(endDateTime.getTime() - startDateTime.getTime());
      const diffInSeconds = Math.floor(diffMilliseconds / 1000);
      return diffInSeconds + ' sec';
        } else {
        return "";
        }
    }
  }
};
</script>
