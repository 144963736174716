<template>
  <form class="needs-validation" novalidate v-on:submit.prevent>
    <div
      class="modal fade"
      id="newAttackModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content bg-dark">
          <div class="modal-header border-color-dark">
            <h5 class="modal-title ms-3">
              <img :src="logo" style="max-height: 2.5rem" />
              Create new attack
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card soft-background-color p-4 mb-3">
                    <div class="mb-2 text-white">
                      <i class="fa-regular fa-file-lines me-1"></i>
                      Load dataset
                    </div>
                    <div class="mb-3">
                      <label for="attackName" class="form-label"
                        >Attack identifier</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="attackName"
                        placeholder="Attack tester"
                        v-model="attack_name"
                        required
                      />
                      <div class="invalid-feedback">
                        Please enter a valid attack name.
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="datasetName" class="form-label"
                        >Dataset for the attack</label
                      >
                      <select
                        class="form-select"
                        id="datasetName"
                        aria-label="Section of the dataset"
                        v-model="dataset"
                        required
                      >
                        <option value="">
                          Choose a dataset used for the attack
                        </option>
                        <option
                          v-for="option in dataset_options"
                          :value="option.id"
                          :key="option.url"
                        >
                          {{ option.text }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid dataset.
                      </div>
                    </div>
                    <hr class="mt-0 mb-2" />
                    <div class="mb-2 text-white">
                      <i class="fa-solid fa-layer-group me-1"></i>
                      Load model
                    </div>
                    <div class="mb-3">
                      <label for="modelName" class="form-label"
                        >Attacked model</label
                      >
                      <select
                        :disabled="!dataset"
                        class="form-select"
                        id="modelName"
                        aria-label="Section of the model"
                        v-model="model"
                        required
                      >
                        <option value="">Choose a model to be attacked</option>
                        <option
                          v-for="pair in model_options"
                          :value="pair.id"
                          :key="pair.url"
                        >
                          {{ pair.text }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid dataset.
                      </div>
                    </div>
                    <hr class="mt-0 mb-2" />
                    <div class="mb-2 text-white">
                      <i class="fa-solid fa-gears"></i>
                      Attack parameters
                    </div>
                    <div class="mb-3">
                      <label for="attackType" class="form-label"
                        >Type of attack</label
                      >
                      <select
                        class="form-select"
                        id="attackType"
                        aria-label="Section of the attack type"
                        v-model="type_of_attack"
                        required
                      >
                        <option value="SynBA" selected>SynBA</option>
                        <option value="TextFooler">TextFooler</option>
                        <option value="GeneticAttack">GeneticAttack</option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid attack type.
                      </div>
                    </div>
                    <div class="row b-3">
                      <div class="col-md">
                        <label for="numSamplesInput">Num of samples</label>
                        <input
                          type="number"
                          class="form-control"
                          id="numSamplesInput"
                          v-model="samples_under_attack"
                          required
                        />
                        <div class="invalid-feedback">
                          Please enter the number of samples from the dataset
                          used for the attack.
                        </div>
                      </div>
                      <div class="col-md mt-3 align-self-center">
                        <div class="form-check form-switch">
                          <label for="randomSeedSwitch">Random seed</label>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="randomSeedSwitch"
                            v-model="is_random_seed"
                          />
                        </div>
                      </div>
                      <div class="col-md mt-3 align-self-center">
                        <div class="form-check form-switch">
                          <label for="advancedMetricsSwitch"
                            >Advanced metrics</label
                          >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="advancedMetricsSwitch"
                            v-model="is_advanced_metrics"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3 ms-1 me-1">
                      <div
                        class="alert alert-danger text-white shadow-lg opacity-5"
                        role="alert"
                        v-show="error"
                      >
                        <strong>Error occurred: </strong>{{ message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                ref="Close"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                class="btn btn-primary"
                type="submit"
                @click="createAttack"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import logo from "@/assets/img/logos/aiaguard.png";
export default {
  name: "NewAttackCard",

  created() {
    axios.defaults.baseURL = this.$store.state.backendURL;
    axios.defaults.withCredentials = true;
  },

  methods: {
    createAttack() {
      let all_valid = this.validate();
      if (all_valid) {
        axios
          .post("/analysis/run/adversarial_attack", {
            attack_name: this.attack_name,
            dataset_used: this.getDatasetUrl,
            dataset_subset_used: this.getDatasetSubset,
            attacked_model: this.getModelUrl,
            type_of_attack: this.type_of_attack,
            samples_under_attack: this.samples_under_attack,
            is_random_seed: this.is_random_seed,
            is_advanced_metrics: this.is_advanced_metrics,
          })
          .then(() => {
            this.error = false;
            this.$refs.Close.click();
            this.$parent.loadAdversarialAttacks();
          })
          .catch((error) => {
            console.log(error);
            this.error = true;
            this.message = error.response.statusText;
          });
      } else {
        this.error = false;
      }
    },

    validate() {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      const forms = document.querySelectorAll(".needs-validation");
      let all_valid = true;
      // Loop over them and prevent submission
      Array.from(forms).forEach((form) => {
        if (!form.checkValidity()) {
          all_valid = false;
        }

        form.classList.add("was-validated");
      });
      return all_valid;
    },
  },

  data() {
    return {
      error: false,
      error_message: "",
      is_advanced_metrics: false,
      is_random_seed: false,
      samples_under_attack: 2,
      logo,
      dataset_options: [
        {
          text: "imdb - Movie reviews",
          url: "imdb",
          id: 1,
        },
        {
          text: "rotten-tomatoes - Movie reviews",
          url: "rotten_tomatoes",
          id: 2,
        },
        {
          text: "yelp_polarity - Yelp reviews",
          url: "yelp_polarity",
          id: 3,
        },
        {
          text: "ag_news - News articles",
          url: "ag_news",
          id: 4,
        },
        {
          text: "CoLA - Corpus of Linguistic Acceptability (grammatical acceptability)",
          url: "glue",
          subset: "cola",
          id: 5,
        },
        {
          text: "SST-2 - Stanford Sentiment Treebank (sentiment of a given sentence)",
          url: "glue",
          subset: "sst2",
          id: 6,
        },
        {
          text: "MNLI - Multi-Genre Natural Language Inference Corpus (hypotesis - premise pair)",
          url: "glue",
          subset: "mnli",
          id: 7,
        },
        {
          text: "QQP - Quora Question Pairs2 (questions equivalence)",
          url: "glue",
          subset: "qqp",
          id: 8,
        },
      ],
      dataset: "",
      model: "",
      model_options: [],
      model_pairs: {
        1: [
          {
            text: "roberta-base",
            url: "textattack/roberta-base-imdb",
            id: 1,
          },
          {
            text: "bert-base-uncased",
            url: "textattack/bert-base-uncased-imdb",
            id: 2,
          },
          {
            text: "distilbert-base-uncased",
            url: "textattack/distilbert-base-uncased-imdb",
            id: 4,
          },
          {
            text: "albert-base-v2",
            url: "textattack/albert-base-v2-imdb",
            id: 5,
          },
        ],
        2: [
          {
            text: "roberta-base",
            url: "textattack/roberta-base-rotten-tomatoes",
            id: 1,
          },
          {
            text: "bert-base-uncased",
            url: "textattack/bert-base-uncased-rotten-tomatoes",
            id: 2,
          },
          {
            text: "distilbert-base-uncased",
            url: "textattack/distilbert-base-uncased-rotten-tomatoes",
            id: 4,
          },
          {
            text: "albert-base-v2",
            url: "textattack/albert-base-v2-rotten-tomatoes",
            id: 5,
          },
        ],
        3: [
          {
            text: "bert-base-uncased",
            url: "textattack/bert-base-uncased-yelp-polarity",
            id: 2,
          },
          {
            text: "albert-base-v2",
            url: "textattack/albert-base-v2-yelp-polarity",
            id: 5,
          },
        ],
        4: [
          {
            text: "roberta-base",
            url: "textattack/roberta-base-ag-news",
            id: 1,
          },
          {
            text: "bert-base-uncased",
            url: "textattack/bert-base-uncased-ag-news",
            id: 2,
          },
          {
            text: "distilbert-base-uncased",
            url: "textattack/distilbert-base-uncased-ag-news",
            id: 4,
          },
          {
            text: "albert-base-v2",
            url: "textattack/albert-base-v2-ag-news",
            id: 5,
          },
        ],
        5: [
          {
            text: "roberta-base",
            url: "textattack/roberta-base-CoLA",
            id: 1,
          },
          {
            text: "bert-base-uncased",
            url: "textattack/bert-base-uncased-CoLA",
            id: 2,
          },
          {
            text: "distilbert-base-uncased",
            url: "textattack/distilbert-base-uncased-CoLA",
            id: 4,
          },
          {
            text: "albert-base-v2",
            url: "textattack/albert-base-v2-CoLA",
            id: 5,
          },
          
        ],
        6: [
          {
            text: "roberta-base",
            url: "textattack/roberta-base-SST-2",
            id: 1,
          },
          {
            text: "bert-base-uncased",
            url: "textattack/bert-base-uncased-SST-2",
            id: 2,
          },
          {
            text: "distilbert-base-uncased",
            url: "textattack/distilbert-base-uncased-SST-2",
            id: 4,
          },
          {
            text: "albert-base-v2",
            url: "textattack/albert-base-v2-SST-2",
            id: 5,
          },
        ],
        7: [
          {
            text: "roberta-base",
            url: "textattack/roberta-base-MNLI",
            id: 1,
          },
          // {
          //   text: "bert-base-uncased",
          //   url: "textattack/bert-base-uncased-MNLI",
          //   id: 2,
          // },
          // {
          //   text: "distilbert-base-uncased",
          //   url: "textattack/distilbert-base-uncased-MNLI",
          //   id: 4,
          // },
        ],
        8: [
          {
            text: "bert-base-uncased",
            url: "textattack/bert-base-uncased-QQP",
            id: 2,
          },
          {
            text: "distilbert-base-uncased",
            url: "textattack/distilbert-base-uncased-QQP",
            id: 4,
          },
          {
            text: "albert-base-v2",
            url: "textattack/albert-base-v2-QQP",
            id: 5,
          },
        ],
      },
    };
  },

  computed: {
    getDatasetUrl() {
      return this.dataset_options.find((option) => option.id === this.dataset)
        .url;
    },
    getDatasetSubset() {
      return this.dataset_options.find((option) => option.id === this.dataset)
        .subset;
    },
    getModelUrl() {
      return this.model_options.find((option) => option.id === this.model).url;
    },
  },

  watch: {
    dataset: function () {
      this.model_options = [];
      if (this.dataset > 0) {
        this.model_options = this.model_pairs[this.dataset];
      }
    },
  },
};
</script>
