<template>
  <div class="container-fluid mt-4 min-vh-75">
    <div class="mt-4 page-header min-height-200 height-lg-100 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved3.jpg') + ')',
      backgroundPositionY: '80%',
    }">
      <span class="mask bg-gradient-dark opacity-7"></span>
    </div>
    <div class="mx-4 overflow-hidden card blur shadow-blur shadow-lg opacity-8 mt-n5">
      <div class="row gx-4 my-2">
        <div class="col-auto">
          <router-link to="/vapt">
            <i class="fa-solid fa-arrow-left text-dark py-3 ms-4"></i>
          </router-link>
        </div>
        <div class="col-auto my-auto">
          <input v-if="edit" type="text"
           id="vapt_name" class="my-auto mr-3" 
           :placeholder="vapt.config.vapt_name"
            v-model="vapt_name" />
          <h6 v-else class="my-auto mr-3 font-weight-bold text-dark">
            {{ vapt.config.vapt_name }}
          </h6>
        </div>
        <div class="col d-flex justify-content-end">
          <div v-if="edit" class="btn mx-4 my-auto" @click="editVapt"><i class="fa-solid fa-save text-dark"></i></div>
          <div v-else class="btn mx-4 my-auto" @click="editVapt"><i class="fa-solid fa-pen-to-square text-dark"></i>
          </div>
          <div class="btn me-4 my-auto" @click="deleteVapt"><i class="fa-regular fa-trash-can text-dark"></i></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 mt-3 d-flex">
        <div class="card card-body p-4">
          <h6>Vapt info</h6>

          <hr class="mt-0 mb-2" />
          <ul class="list-group">
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Target url:</strong> &nbsp;
              {{ vapt.config.target_url }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Ajax Spider:</strong>
              &nbsp; {{ getCheckAjaxSpider }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Active scan:</strong>
              &nbsp; {{ getCheckActiveScan }}
            </li>
            <div class="row">
              <div class="col-auto">
                <li class="pt-0 pb-0 text-sm text-white border-0 list-group-item ps-0 my-auto">
                  <strong class="text-light text-bold">Status:</strong> &nbsp;
                  {{ vapt.status }}
                </li>
              </div>
            </div>
          </ul>
        </div>
      </div>


    </div>
    <div class="card card-body m-3 p-4 min-height-100 text-sm">
      <h6>VAPT result</h6>
      <hr class="mt-0 mb-2" />
      <div v-if="vapt.result!==null">
      <div v-for="(item, key) in vapt.result" :key="key"
        class="card card-body bg-dark text-dark text-xs font-weight-bold pt-3 pb-0 mb-1 blur shadow-lg opacity-8">
        <div v-if="key !== 'site'">{{ key }}:{{ item }}</div>

      </div>

      <div v-for="(item, key) in vapt.result.site" :key="key"
        class="card card-body bg-dark text-dark text-xs font-weight-bold pt-3 pb-0 mb-1 blur shadow-lg opacity-8">
        <div v-for="(i, key) in item" :key=key>
          <div v-if="key === 'alerts'">
            <div v-for="(item, key) in i" :key="key">
              <div v-for="(i, key) in item" :key=key> {{ key }}:{{ i }} </div>

            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>

    </div>

  </div>
</template>
  
<script>
import axios from "axios";
import setTooltip from "@/assets/js/tooltip.js";


export default {
  name: "VaptResult",

  data() {
    return {
      edit: false,
      // Data about attack read from API
      vapt: {
        config: {
          vapt_name: "",
          target_url: "",
          ajax_spider: false,
          active_scan: false,
          api_format: null
        },
        result: {
        },
        status: "",
        error: null,
        warnings: null,
        running: false,
        date_start: "",
        date_end: "",
      },
    };
  },

  methods: {
    editVapt() {
      // invert boolean value
      if (this.edit) {
        this.edit = false
        console.log("editing")
        console.log(String(this.vapt_name))
        axios
          .put("/analysis/history/update/vapt/" + this.id + "?vapt_name=" + this.vapt_name)
          .then((response) => {
            this.vapt = response.data;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
          })
      } else {
        this.edit = true
      }
    },
    deleteVapt() {
      axios
        .delete("/analysis/history/delete/" + this.id)
        .then((response) => {
          console.log(response.status);
          this.$router.push("/vapt")
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
    },
    loadVaptAnalysis() {
      console.log("load analysis", this.id);
      axios
        .get("/analysis/history/" + this.id + "/full")
        .then((response) => {
          //this.vapt = response.data;
          console.log(response.status);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },

  computed: {
    getAction() {
      if (this.vapt.running) {
        return 'Stop <i class="fa-solid fa-stop ms-2"></i> ';
      } else {
        return 'Run<i class="fa-solid fa-play ms-2"></i>';
      }
    },
    getCheckAjaxSpider() {
      if (this.vapt.config.ajax_spider) {
        return "Yes";
      } else {
        return "No";
      }
    },
    getCheckActiveScan() {
      if (this.vapt.config.active_scan) {
        return "Yes";
      } else {
        return "No";
      }
    }
  },

  components: {
    //SoftProgress,
  },

  created() {
    this.id = this.$route.params.id;
    this.loadVaptAnalysis();
  },

  mounted() {
    setTooltip();

    //Probe the server for the analysis every 10 seconds:
    this.interval = setInterval(() => {
      this.loadVaptAnalysis();
    },
      10000
    );
  },

  //On component deletion:
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
  