import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import AdversarialAttack from "@/views/AdversarialAttack/AdversarialAttack.vue";
import AdversarialAttackResult from "@/views/AdversarialAttack/AdversarialAttackResult.vue";
import DataAnonymization from "@/views/DataAnonymization/DataAnonymization.vue";
import DataAnonymizationResult from "@/views/DataAnonymization/DataAnonymizationResult.vue";
import Profile from "@/views/Profile.vue";
import SignUp from "@/views/SignUp.vue";
import SignIn from "@/views/SignIn.vue";
import store from '@/store';
import Ast from "@/views/Ast/Ast.vue";
import AstResult from "@/views/Ast/AstResult.vue";
import Vapt from "@/views/Vapt/Vapt.vue";
import VaptResult from "@/views/Vapt/VaptResult.vue";
import Interpretability from "@/views/Interpretability/Interpretability.vue";
import InterpretabilityResult from "@/views/Interpretability/InterpretabilityResult.vue";


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard"
  },
  // otherwise redirect to home
  { 
    path: '/:catchAll(.*)',
    name: '404',
    redirect: '/' 
  },
  {
     path: "/dashboard",
     name: "Dashboard",
     component: Dashboard,
  },
  {
    path: "/adversarial-attack",
    name: "Adversarial attack",
    component: AdversarialAttack,
  },
    {
    path: "/data-anonymization",
    name: "Data anonymization",
    component: DataAnonymization,
  },
  {
    path: '/adversarial-attack/:id',
    name: 'Adversarial attack result',
    component: AdversarialAttackResult,
  },
   {
    path: '/data-anonymization/:id',
    name: 'Data anonymization result',
    component: DataAnonymizationResult,
  },
  {
    path: "/ast",
    name: "Ast",
    component: Ast,
  },
  {
    path: "/ast/:id",
    name: "Ast result",
    component: AstResult,
  },
  {
    path: "/vapt",
    name: "Vapt",
    component: Vapt,
  },
  {
    path: "/vapt/:id",
    name: "Vapt result",
    component: VaptResult,
  },
  {
    path: "/interpretability",
    name: "Interpretability",
    component: Interpretability,
  },
  {
    path: "/interpretability/:id",
    name: "Interpretability result",
    component: InterpretabilityResult,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/sign-in', '/sign-up'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getters.getLoginUserInfo;

  if (authRequired && !loggedIn) {
    return next('/sign-up');

  } else {
    next();
  }
})

export default router;
