<template>
  <div class="container-fluid mt-4">
    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-primary ms-auto me-3" @click="loadAdversarialAttacks"><i class="fa-solid fa-rotate-right"></i></button>
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newAttackModal">New attack</button>
    </div>
    
    <div v-if="this.adversarialAttacksList.length == 0" class="row min-vh-65">
      <div class="card text-center mx-auto mt-auto mb-auto w-75">
        <div class="card-body mt-4">
          <p class="card-text">Choose a deep learning model and create a new adversarial attack</p>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newAttackModal">New attack</button>
        </div>
      </div>
    </div>
    <div v-else class="min-vh-65">
      <adversarial-attack-item
        v-for="(adversarialAttack, index) of this.adversarialAttacksList"
          :key="index"
          :id=adversarialAttack._id
          :name=adversarialAttack.config.attack_name
          :successful_attacks=adversarialAttack.output.successful_attacks
          :failed_attacks=adversarialAttack.output.failed_attacks
          :skipped_attacks=adversarialAttack.output.skipped_attacks
          :status=adversarialAttack.status
          :date_start=adversarialAttack.date_start
      />
    </div>

  </div>
  <button ref="Alert" type="button" class="invisible" data-bs-toggle="modal" data-bs-target="#alertModal"></button>
  <NewAttackCard />
  <alert-card 
    :message=error_message
    :status=status
  />

</template>

<script>
import axios from "axios";

import NewAttackCard from "@/views/AdversarialAttack/NewAttackCard.vue";
import AdversarialAttackItem from "@/views/AdversarialAttack/AdversarialAttackItem.vue";
import AlertCard from "@/components/AlertCard.vue";

export default {
  name: "AdversarialAttack",
  components: {
    NewAttackCard,
    AdversarialAttackItem,
    AlertCard,
  },

  data() {
    return {
      isActive: "active",
      error_message: "",
      errored: false,
      adversarialAttacksList: [],
      status: 200
    };
  },

  methods: {
    loadAdversarialAttacks() {
      axios
      .get('analysis/history/by-module/adversarial_attack')
      .then(response => {
        this.adversarialAttacksList = response.data
      })
      .catch(error => {
        console.log(error)
        this.errored = true
        this.error_message = error.message
        this.status = error.response.status
        this.$refs.Alert.click()
      })
      .finally(() => this.loading = false)
    },
  },

  created() {
    this.loadAdversarialAttacks();
  },

  mounted() {

     //Probe the server for the analysis every 10 seconds:
     this.interval = setInterval(() => 
      {
        this.loadAdversarialAttacks();
      },
      10000
    );
  },

  //On component deletion:
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
