<template>
  <div class="container-fluid mt-4 min-vh-75">
    <div class="mt-4 page-header min-height-200 height-lg-100 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved3.jpg') + ')',
      backgroundPositionY: '80%',
    }">
      <span class="mask bg-gradient-dark opacity-7"></span>
    </div>
    <div class="mx-4 overflow-hidden card blur shadow-blur shadow-lg opacity-8 mt-n5">
      <div class="row gx-4 my-2">
        <div class="col-auto">
          <router-link to="/interpretability">
            <i class="fa-solid fa-arrow-left text-dark py-3 ms-4"></i>
          </router-link>
        </div>
        <div class="col-auto my-auto">
          <input v-if="edit" type="text" id="interpretability_name" 
          class="my-auto mr-3" 
          :placeholder="interpretability.config.interpretability_name"
            v-model="interpretability_name" />
          <h6 v-else class="my-auto mr-3 font-weight-bold text-dark">
            {{ interpretability.config.interpretability_name }}
          </h6> 
        </div>
        <div class="col d-flex justify-content-end">
          <div v-if="edit" class="btn mx-4 my-auto" @click="editInterpretability"><i class="fa-solid fa-save text-dark"></i></div>
          <div v-else class="btn mx-4 my-auto" @click="editInterpretability"><i class="fa-solid fa-pen-to-square text-dark"></i>
          </div>
          <div class="btn me-4 my-auto" @click="deleteInterpretability"><i class="fa-regular fa-trash-can text-dark"></i></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 mt-3 d-flex">
        <div class="card card-body p-4">
          <h6>Interpretability info</h6>
          <hr class="mt-0 mb-2" />
          <ul class="list-group">
            <div class="row">
              <div class="col-auto">
                <li class="pt-0 pb-0 text-sm text-white border-0 list-group-item ps-0 my-auto">
                  <strong class="text-light text-bold">Status:</strong> &nbsp;
                  {{ interpretability.status }}
                </li>
              </div>
            </div>
          </ul>
          <ul class="list-group" v-if="this.interpretability.result.results[0].type === '1' || this.interpretability.result.results[0].type === '2'" >
            <div class="row">
              <div class="col-auto">
                <li class="pt-0 pb-0 text-sm text-white border-0 list-group-item ps-0 my-auto">
                  <strong class="text-light text-bold">Original text (or first part of it): {{ interpretability.result.results[0].sentence }}  </strong> &nbsp;
                </li>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="card card-body m-3 p-4 min-height-100 text-sm">
        <h6>Interpretability results</h6>
        <hr class="mt-0 mb-2" />
        <ul class="card card-body bg-dark text-uppercase text-white text-xs font-weight-bolder pt-3 pb-0 mb-1 shadow-lg" v-if="this.interpretability.result.results[0].type === '1' || this.interpretability.result.results[0].type === '2'">
          <div class="row">
            <div class="col-md-2 mb-3 d-flex justify-content-start">
              <div style="width: 40px; min-width: 40px">#</div>
              Model prediction
            </div>
            <div class="col-md-5 mb-3 justify-content-start">Shapley values of the elements</div>
          </div>
        </ul>
        <ul class="list-group" v-if="this.interpretability.result.results[0].type === '1' || this.interpretability.result.results[0].type === '2'" >
          <div
            v-for="(result, index) in interpretability.result.results"
            :key="index"
            class="card card-body bg-dark text-dark text-xs font-weight-bold pt-3 pb-0 mb-1 blur shadow-lg opacity-8">

            <div class="row">
            <span v-for="(prediction, index) in result.prediction" :key="index" class="badge badge-sm bg-dark text-info fs-6" v-html="prediction"></span>
            <span v-for="(shapley, index) in result.shapley" :key="index" class="col-md-11 mb-3 justify-content-start" v-html="shapley"></span>
            </div>
          </div>
        </ul>
        <ul class="list-group" v-if="this.interpretability.result.results[0].type === '3'" >
          <li>
          <div
            class="card card-body bg-dark text-dark text-xs font-weight-bold pt-3 pb-0 mb-1 blur shadow-lg opacity-8">
            <div class="row">

              <img :src=imageURL />

            </div>
          </div>
        </li>
        </ul>
        <ul class="list-group" v-if="this.interpretability.result.results[0].type === '4'" >
          <li>
          <div
            class="card card-body bg-dark text-dark text-xs font-weight-bold pt-3 pb-0 mb-1 blur shadow-lg opacity-8">
            <div class="row">

              <img :src=imageURL />

            </div>
          </div>
        </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "InterpretabilityResult",

  data() {
    return {
      edit: false,
      // Data read from API
      imageURL: '', // Add correct image URL here, file location is saved in results.shapley in the images image case
      showImage: true,
      interpretability: {
        config: {
          interpretability_name: "",
          sentence_analyzed: "",
          model_used: "",
          method: "shap",
          type: ""

        },
        output:{},
        result: {
          mouse: "",
          results: [],
        },
        status: "",
        error: null,
        warnings: null,
        running: false,
        date_start: "",
        date_end: "",
      },
    };
  },

  methods: {
    editInterpretability() {
      // invert boolean value
      if (this.edit) {
        this.edit = false
        console.log(String(this.interpretability_name))
        axios
          .put("/analysis/history/update/interpretability/" + this.id + "?interpretability_name=" + this.interpretability_name)
          .then((response) => {
            this.interpretability = response.data;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
          })
      } else {
        this.edit = true
      }
    },
    deleteInterpretability() {
      axios
        .delete("/analysis/history/delete/" + this.id)
        .then((response) => {
          console.log(response.status);
          this.$router.push("/interpretability")
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
    },
    loadInterpretabilityAnalysis() {
      console.log("load analysis", this.id);
      axios
        .get("/analysis/history/" + this.id + "/full")
        .then((response) => {
          console.log("Loaded");
          this.interpretability = response.data;
          //console.log(response.data);
          this.imageURL = 'data:image/jpeg;base64,' + response.data.result.results[0].image.image_data;
          console.log(this.interpretability.result.results[0].type)
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    async mousestr(mouse) {
      try {
        return mouse;
      } catch (error) {
        console.error('Error fetching mouse data:', error);
        throw error;
      }
    },
  },

  computed: {
    getAction() {
      if (this.interpretability.running) {
        return 'Stop <i class="fa-solid fa-stop ms-2"></i> ';
      } else {
        return 'Run<i class="fa-solid fa-play ms-2"></i>';
      }
    },
  },

  components: {
    //SoftProgress,
  },

  created() {
    this.id = this.$route.params.id;
    this.loadInterpretabilityAnalysis();
  },

  async mounted() {
    const self = this;
    const response = await axios.get("/analysis/history/" + this.id + "/full");
    const mice = response.data.result.results[0].mouse;
    // Create mouse window functions dynamically (copied from shap html), || Click locks out mouseover - fix maybe unnecessary
    for (let i = 0; i < mice.length; i++) {
      const mouse = mice[i];
      const functionName = "_output_onmouseover_" + await self.mousestr(mouse);
      const functionName2 = "_output_onclick_" + await self.mousestr(mouse);
      window[functionName] = async function(i, el) {
        const mouseRes = await self.mousestr(mouse);
        console.log("Mouseover function executed.");
        if (document["_zoom_" + mouseRes] !== undefined) { return; }
          if (document["_hover_" + mouseRes] !== undefined) {
            document.getElementById(document["_hover_" + mouseRes] + '_name').style.borderBottom = 'none';
            document.getElementById(document["_hover_" + mouseRes]).style.display = 'none';
          }
        document.getElementById(["_tp_" + mouseRes + "_output_"] + i).style.display = 'block';
        el.style.borderBottom = '3px solid #000000';
        document["_hover_" + mouseRes] = "_tp_" + mouseRes + "_output_" + i;
        console.log(`Mouse function ${functionName} called with parameters: `, i, el);
      };

      window[functionName2] = async function(i) {
      const mouseRes = await self.mousestr(mouse);
      console.log("Mouseclick function executed.");
      var next_id = undefined;
  
        if (document["_zoom_" + mouseRes] !== undefined) {
          document.getElementById(document["_zoom_" + mouseRes] + '_zoom').style.display = 'none';
  
          if (document["_zoom_" + mouseRes] === ["_tp_" + mouseRes + "_output_"] + i) {
            document.getElementById(document["_zoom_" + mouseRes]).style.display = 'block';
            document.getElementById(document["_zoom_" + mouseRes] +'_name').style.borderBottom = '3px solid #000000';
          } else {
            document.getElementById(document["_zoom_" + mouseRes]).style.display = 'none';
            document.getElementById(document["_zoom_" + mouseRes] +'_name').style.borderBottom = 'none';
          }
        }
        if (document["_zoom_" + mouseRes] !== '_tp_ + mouseRes_output_' + i) {
          next_id = ["_tp_" + mouseRes + "_output_"] + i;
          document.getElementById(next_id).style.display = 'none';
          document.getElementById(next_id + '_zoom').style.display = 'block';
          document.getElementById(next_id+'_name').style.borderBottom = '3px solid #000000';
        }
      document["_zoom_" + mouseRes] = next_id;
    };
    }

    setTooltip();    
    //Probe the server for the analysis every 10 seconds:
    this.interval = setInterval(() => {
      this.loadInterpretabilityAnalysis();
    },
    10000
    );
  },

  //On component deletion:
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
