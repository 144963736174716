<template>
  <div class="loading-indicator text-center py-5">
    <!-- Bootstrap Spinner for Loading Indicator -->
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
};
</script>

<style scoped>
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}
</style>