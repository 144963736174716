<template>
    <div
      class="modal fade"
      id="alertModal"
      tabindex="-1"
      aria-labelledby="alertModal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
      >
        <div class="modal-content bg-dark">
          <div class="modal-header border-color-dark">
            <h5 class="modal-title ms-3">Error occurred</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="Close"
            ></button>
          </div>
          <div class="modal-body d-flex">
            <p class="mx-auto">{{message}}</p>
          </div>
            <div v-if="status == 401" class="modal-footer">
              <button
                class="btn btn-primary"
                @click="logout"
              >
                Logout
              </button>
            </div>
          
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "AlertCard",
  props: {
      message: {
        type: String,
        required: true
      },
      status: {
        type: Number,
        required: true
      }
  },
  methods: {
    logout() {
      this.$refs.Close.click();
      this.$router.push("/sign-up");
    }
  }

};
</script>
