<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }} 
            <a
              href="https://aiaguard.com/"
              class="font-weight-bold text-muted"
              target="_blank"
              >AIAGuard</a
            >
            , All Rights Reserved.
            <a
              href="https://aiaguard.com/cookies.html"
              class="font-weight-bold text-muted"
              target="_blank"
              >Cookie policy</a
            >
          </div>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
          <span class="text-sm me-5">Crafted by <a class="text-muted" href="https://datrixgroup.com/">Datrix S.p.A.</a></span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
