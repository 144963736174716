<template>
  <main class="mt-0 main-content main-content-bg bg-dark">
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/components/FooterSignUp.vue";
import { mapMutations, mapActions } from "vuex";
import axios from "axios";


export default {
  name: "SignIn",
  components: {
    AppFooter,
  },

  async  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    axios.defaults.baseURL = this.$store.state.backendURL;
    axios.defaults.withCredentials = true;
    this.setUser();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setLoginUser", "logoutUser"]),

    async setUser() {
      await axios.get('users/me')
        .then(user => {
            let userdata = user.data;
            this.setLoginUser(userdata);
            this.$router.push({ name: "/" });
        })
        .catch(error => {console.log(error);
          this.logoutUser();
          this.$router.push({ name: "Sign Up" });
        })
    },
  },
};
</script>
