<template>
  <div v-if="!isDeleted">
    <router-link :to="{ name: 'Adversarial attack result', params: { id: id } }" class="btn card mb-3 shadow">
      <div class="row d-flex align-items-center">
        <div class="col text-white">
          {{ name }}
        </div>
        <div class="col">
          {{ this.getStartDate }}
        </div>
        <div class="col">
          Successful: <span class="text-success">{{ getResult(this.successful_attacks) }}</span>
        </div>
        <div class="col">
          Failed: <span class="text-danger">{{ getResult(this.failed_attacks) }}</span>
        </div>
        <div class="col">
          Skipped: <span class="text-warning">{{ getResult(this.skipped_attacks) }}</span>
        </div>
        <div class="col p-0">
          <div class="btn m-0">{{ this.status }}</div>
        </div>
        <div class="col-auto">
          <button class="btn me-4 my-auto text-white delete-icon" @click.stop.prevent="deleteDaResult"
            title="Delete analysis">
            <i class="fa-regular fa-trash-can"></i>
          </button>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {
  name: "AdversarialAttackItem",
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: "",
    },
    successful_attacks: {
      type: Number,
      default: 0,
    },
    failed_attacks: {
      type: Number,
      default: 0,
    },
    skipped_attacks: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: "",
    },
    date_start: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isDeleted: false,
    };
  },
  methods: {
    getResult(value) {
      if (value > 0) {
        return value;
      } else {
        return "—";
      }
    },
    deleteDaResult() {
      if (confirm("Are you sure you want to delete this item?")) {
        axios.delete("/analysis/history/delete/" + this.id)
          .then(() => {
            this.isDeleted = true;
          })
          .catch((error) => {
            console.log(error);
            console.log(error);
          });
      }
    },
  },
  computed: {
    getStartDate() {
      return moment(this.date_start).format("DD/MM/YYYY HH:mm");
    }
  }
};
</script>
