<template>
  <div class="container-fluid mt-4">
    <div v-if="loadingInterpretability" class="loading-overlay">
      <loading-indicator></loading-indicator>
    </div>
    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-primary ms-auto me-3" @click="loadInterpretabilityAnalysis"><i
          class="fa-solid fa-rotate-right"></i></button>
      <button type="button" class="btn btn-primary" data-bs-toggle="modal"
        data-bs-target="#newInterpretabilityModal">New
        run</button>
    </div>

    <div v-if="this.interpretabilityAnalysisList.length == 0" class="row min-vh-65">
      <div class="card text-center mx-auto mt-auto mb-auto w-75">
        <div class="card-body mt-4">
          <p class="card-text">Choose a model and run Interpretability</p>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#newInterpretabilityModal">New
            run</button>
        </div>
      </div>
    </div>
    <div v-else class="min-vh-65">
      <interpretability-item v-for="(interpretability, index) in this.interpretabilityAnalysisList" :key="index"
        :id="interpretability._id" :interpretability_name="interpretability.config.interpretability_name"
        :status="interpretability.status" :date_start="interpretability.date_start"
        :date_end="interpretability.date_end"
        :model_used="interpretability.config.model_used" :type_of_input="interpretability.config.input_selection"
        :analysis_selected="interpretability.config.analysis_selected" />
    </div>
  </div>
  <button ref="Alert" type="button" class="invisible" data-bs-toggle="modal" data-bs-target="#alertModal"></button>
  <NewInterpretabilityCard />
  <alert-card :message="error_message" :status="status" />
</template>

<script>
import axios from "axios";
import NewInterpretabilityCard from "@/views/Interpretability/NewInterpretabilityCard.vue";
import InterpretabilityItem from "@/views/Interpretability/InterpretabilityItem.vue";
import AlertCard from "@/components/AlertCard.vue";
import LoadingIndicator from './LoadingIndicator.vue';

export default {
  name: "Interpretability",
  components: {
    NewInterpretabilityCard,
    InterpretabilityItem,
    AlertCard,
    LoadingIndicator,
  },

  data() {
    return {
      isActive: "active",
      error_message: "",
      errored: false,
      loadingInterpretability: false,
      interpretabilityAnalysisList: [],
      status: 200
    };
  },

  methods: {
    loadInterpretability() {
      this.loadingInterpretability = true;
      axios
        .get('analysis/history/by-module/interpretability')
        .then(response => {
          //console.log('API Response:', response.data);
          //response.data.forEach(item => {
          //  console.log('Config for item', item.id, ':', item.config);
          //});
          this.interpretabilityAnalysisList = response.data;
        })
        .catch(error => {
          console.log(error);
          this.errored = true;
          this.error_message = error.message;
          this.status = error.response ? error.response.status : 500;
          this.$refs.Alert.click();
        })
        .finally(() => {
          this.loadingInterpretability = false;
        });
    },
  },
  created() {
    this.loadInterpretability();
  },

  mounted() {
    this.interval = setInterval(this.loadInterpretability, 30000);
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>