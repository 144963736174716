<template>
  <div v-if="!isDeleted" class="btn card mb-3 shadow">
    <div class="row d-flex align-items-center">
      <div class="col text-white" @click.stop="navigateToResult(id)">
        {{ vapt_name }}
      </div>
      <div class="col" @click.stop="navigateToResult(id)">
        {{ this.getStartDate }}
      </div>
      <div class="col p-0" @click.stop="navigateToResult(id)">
        <div class="btn m-0">{{ this.status }}</div>
      </div>
      <div class="col-auto">
        <button class="btn me-4 my-auto text-white delete-icon" @click.stop.prevent="deleteVapt" title="Delete VAPT">
          <i class="fa-regular fa-trash-can"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: "VaptItem",
  props: {
    id: {
      type: String,
      required: true
    },
    vapt_name: {
      type: String,
      default: "",
    },
    target_url: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    date_start: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isDeleted: false,
    };
  },
  methods: {
    navigateToResult(id) {
      this.$router.push({ name: 'Vapt result', params: { id: id } });
    },
    deleteVapt() {
      if (confirm("Are you sure you want to delete this VAPT?")) {
        axios.delete("/analysis/history/delete/" + this.id)
          .then(() => {
            this.isDeleted = true;
          })
          .catch((error) => {
            console.error(error);
            this.errored = true;
          });
      }
    },
  },
  computed: {
    getStartDate() {
      return moment(this.date_start).format("DD/MM/YYYY HH:mm");
    }
  }
};
</script>
