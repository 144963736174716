<template>
  <div class="container-fluid mt-4">
    <div class="d-flex justify-content-end">

      <button type="button" class="btn btn-primary ms-auto me-3" @click="loadAstAnalysis"><i
          class="fa-solid fa-rotate-right"></i></button>
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newAstModal">New
        run</button>
    </div>

    <div v-if="this.astAnalysisList.length == 0" class="row min-vh-65">
      <div class="card text-center mx-auto mt-auto mb-auto w-75">
        <div class="card-body mt-4">
          <p class="card-text">Choose a repository and run Static Code Analysis</p>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newAstModal">New
            run</button>
        </div>
      </div>
    </div>
    <div v-else class="min-vh-65">
      <ast-item v-for="(ast, index) of this.astAnalysisList" :key="index" :id=ast._id :ast_name=ast.config.ast_name
        :git_url=ast.config.git_url :status=ast.status :date_start=ast.date_start />
    </div>

  </div>
  <button ref="Alert" type="button" class="invisible" data-bs-toggle="modal" data-bs-target="#alertModal"></button>
  <NewAstCard />
  <alert-card :message=error_message :status=status />

</template>
  
<script>
import axios from "axios";

import NewAstCard from "@/views/Ast/NewAstCard.vue";
import AstItem from "@/views/Ast/AstItem.vue";
import AlertCard from "@/components/AlertCard.vue";

export default {
  name: "Ast",
  components: {
    NewAstCard,
    AstItem,
    AlertCard,
  },

  data() {
    return {
      isActive: "active",
      error_message: "",
      errored: false,
      astAnalysisList: [],
      status: 200
    };
  },

  methods: {
    loadAst() {
      axios
        .get('analysis/history/by-module/ast')
        .then(response => {
          this.astAnalysisList = response.data
        })
        .catch(error => {
          console.log(error)
          this.errored = true
          this.error_message = error.message
          this.status = error.response.status
          this.$refs.Alert.click()
        })
        .finally(() => this.loading = false)
    },
  },

  created() {
    this.loadAst();
  },

  mounted() {

    //Probe the server for the analysis every 10 seconds:
    this.interval = setInterval(() => {
      this.loadAst();
    },
      10000
    );
  },

  //On component deletion:
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
  