<template>

  <div
    class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved3.jpg') + ')',
    }"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">AIA Guard – Make your AI Systems safe & reliable!</h1>
          <p class="text-white text-lead">
            An end-to-end cybersecurity solution specifically designed against Artificial Intelligence Attacks.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container ">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5>Sign up to hop on a trial!</h5>
            <div class="mt-4 position-relative text-center">
              <img :src="logo" style="max-height: 4.0rem" />
            </div>
          </div>
          <div class="card-body mt-n4">

            <div class="col d-grid gap-1 position-relative text-center ">
                <button type="button" class="btn btn-primary btn-block" @click="loginGoogle">
                  <svg
                  width="24px"
                  height="32px"
                  viewBox="0 0 64 64"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      transform="translate(3.000000, 2.000000)"
                      fill-rule="nonzero"
                    >
                      <path
                        d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                        fill="#4285F4"
                      />
                      <path
                        d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                        fill="#34A853"
                      />
                      <path
                        d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                        fill="#FBBC05"
                      />
                      <path
                        d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                        fill="#EB4335"
                      />
                    </g>
                  </g>
                </svg>
                  <span class="ms-2"> Sign in with Google </span>
                </button>
            </div>
            <div class="row">
              <div class="col d-grid gap-1">
                <button type="button" class="btn btn-primary" @click="loginGithub">
                  <i class="fa-brands fa-github me-2"></i>
                  <span> Github </span>
                </button>
              </div>
              <div class="col d-grid gap-1">
                <button type="button" class="btn btn-primary" @click="loginBitbucket">
                  <i class="fa-brands fa-bitbucket me-2"></i>
                  <span> Bitbucket </span>
                </button>
              </div>
            </div>
          <div class="text-center">
          <span class="text-xxs">We'll never post to any of your accounts without your permission.</span>
        </div>
        <div class="text-center">
          <span class="text-xxs">By signining in you are agreeing to our
            <a
              href="https://aiaguard.com/terms.html"
              class="font-weight-bold text-muted"
              target="_blank"
              >Terms and condition</a
            > and
            <a
              href="https://aiaguard.com/terms.html#privacy"
              class="font-weight-bold text-muted"
              target="_blank"
              >privacy policy</a
            >
           </span>
        </div>
        </div>

        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import AppFooter from "@/components/FooterSignUp.vue";
import logo from "@/assets/img/logos/aiaguard.png";
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  name: "SignUp",
  components: {
    AppFooter,
  },
  data() {
    return {
      logo,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    axios.defaults.baseURL = this.$store.state.backendURL;
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async loginGoogle() {
      await axios
            .get('auth/google/authorize')
            .then(response => {window.location.replace(response.data.authorization_url);})
            .catch(error => {
                console.log(error)
            });
    },
    async loginBitbucket() {
      await axios
            .get('auth/bitbucket/authorize')
            .then(response => {window.location.replace(response.data.authorization_url);})
            .catch(error => {
                console.log(error)
            });
    },
    async loginGithub() {
      await axios
            .get('auth/github/authorize')
            .then(response => { window.location.replace(response.data.authorization_url);
            })
            .catch(error => {
                console.log(error)
            });
    },
  },
};
</script>
