<template>
  <div class="py-4 container-fluid">
    <div v-if="isAnyLoading" class="loading-overlay">
      <loading-indicator></loading-indicator>
    </div>
    <div v-if="showAlert" class="alert alert-secondary p-1 d-flex align-items-center position-relative">
      <img src="@/assets/img/small-logos/gdpr.png" style="width: 60px; height: 60px;" alt="gdpr compliant" />
      <div class="text-center position-relative flex-grow-1" role="alert">
        <span style="color: white;">We do not store any data but please be careful while uploading sensitive data to the internet</span>
      </div>
      <i class="fa-regular fa-circle-xmark close-alert position-absolute end-0 me-2" aria-hidden="true" @click="showAlert = false" title="Close"></i>
    </div>
    <div class="card text-center mx-auto form-inside-grey-background">
      <div class="card-body mt-4">
        <form enctype="multipart/form-data">
          <div class="d-flex justify-content-center">
            <div
              class="file-and-button-container d-flex justify-content-between"
            >
              <input
                type="file"
                class="form-control file-input"
                @change="handleFileSelection"
                ref="fileInput"
                accept=".csv"
                required
              />
              <button
                type="button"
                class="btn btn-primary ml-2 use-example-btn"
                @click="useExampleCsv"
              >
                Use example CSV
              </button>
            </div>
            <div class="ms-3 form-check form-switch">
              <label for="anonimizeAll">Select columns to anonymise</label>
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="anonimizeAll"
                v-model="anonimizeAll"
              />
            </div>
          </div>
          <div v-if="!anonimizeAll" class="d-flex justify-content-center">
          <div class="d-flex justify-content-between">
            <input
              type="text"
              class="form-control"
              id="customIds"
              placeholder="i.e. 0,2,3 or name,email,date"
              v-model="customIds"
            />
            <label for="customIds" class="form-label d-flex justify-content-between"
              >Indexes (or keys) to be anonymised</label></div>
          </div>
          <div v-if="usingExampleCsv" class="info-message mt-3">
            The
            <a href="example.csv" download="example.csv" class="example-link"
              >example.csv</a
            >
            file will be used.
          </div>
          <div class="mt-3 button-group">
            <button
              type="button"
              class="btn btn-primary m-1 group-button"
              @click="submitFile('basic')"
              :disabled="isAnyLoading || (!file && !usingExampleCsv)"
            >
              Run basic scan
            </button>
            <button
              type="button"
              class="btn btn-primary m-1 group-button"
              @click="submitFile('anonymise')"
              :disabled="isAnyLoading || (!file && !usingExampleCsv)"
            >
              Anonymise
            </button>
          </div>
        </form>
      </div>
      <div class="row mt-3 ms-1 me-1">
        <div
          class="alert alert-danger text-white shadow-lg opacity-5"
          role="alert"
          v-show="error"
        >
          <strong>Error occurred:</strong> {{ message }}
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <data-anonymization-item
        v-for="(daResult, index) in daResultList"
        :key="index"
        :id="daResult._id"
        :outputFileName="daResult?.output ? daResult.output.file : ''"
        :fileName="daResult.config.original_csv_name"
        :result="daResult.result"
        :analysis_type="daResult.config.analysis_type"
        :numFilthyColumns="
          daResult.result ? Object.keys(daResult.result).length : 0
        "
        :status="daResult.status"
        :date_start="daResult.date_start"
        :date_end="daResult.date_end ? daResult.date_end : ''"
        :error="daResult.error"
      />
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import DataAnonymizationItem from "@/views/DataAnonymization/DataAnonymizationItem.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import "@/assets/css/DataAnonymizationStyles.css";

export default {
  components: {
    DataAnonymizationItem,
    LoadingIndicator,
  },
  setup() {
    const file = ref(null);
    const daResultList = ref([]);
    const usingExampleCsv = ref(false);
    const anonimizeAll = ref(true);
    const customIds = ref("");
    const loadingBasic = ref(false);
    const loadingAnonymise = ref(false);
    const noFileSelectedError = ref(false);
    const error = ref(false);
    const message = ref("");
    const fileInput = ref(null);
    const showAlert = ref(true);

    const isAnyLoading = computed(
      () => loadingBasic.value || loadingAnonymise.value
    );
    const isFileSelected = computed(() => !!file.value);

    const readFile = (event) => {
      file.value = event.target.files[0];
      noFileSelectedError.value = !file.value;
      if (file.value) {
        usingExampleCsv.value = false;
      }
    };

    const handleFileSelection = (event) => {
      readFile(event);
      usingExampleCsv.value = false;
    };

    async function submitFile(type, exampleFile = null) {
      if (!file.value && !exampleFile) {
        noFileSelectedError.value = true;
        return;
      }

      noFileSelectedError.value = false;
      const loadingKey = type === "basic" ? loadingBasic : loadingAnonymise;
      loadingKey.value = true;

      const formData = new FormData();

      if (!anonimizeAll.value) {
        formData.append("custom_columns", customIds.value); //TODO
      }

      if (exampleFile) {
        formData.append("file", exampleFile);
      } else {
        formData.append("file", file.value);
      }
      formData.append("analysis_type", type);

      try {
        await axios.post("/analysis/run/da", formData, {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        });
        await loadDAresults();
      } catch (err) {
        console.error(err);
        error.value = true;
        message.value =
          "An unexpected error has occurred. Please check your file for any inconsistencies or try again later.";
      } finally {
        loadingKey.value = false;
      }
    }

    async function useExampleCsv() {
      // Toggle functionality
      if (usingExampleCsv.value) {
        usingExampleCsv.value = false;
        file.value = null;
        if (fileInput.value) {
          fileInput.value.value = "";
        }
        noFileSelectedError.value = false;
      } else {
        console.log("Using example CSV");
        const exampleCsvUrl =
          window.location.origin + process.env.BASE_URL + "example.csv";
        console.log(exampleCsvUrl);
        usingExampleCsv.value = true;
        file.value = null;
        if (fileInput.value) {
          fileInput.value.value = "";
        }
        try {
          // Fetch the example CSV as a Blob
          const response = await axios.get(exampleCsvUrl, {
            responseType: "blob",
          });
          const exampleCsvBlob = response.data;

          // Convert the Blob into a File object
          const exampleCsvFile = new File([exampleCsvBlob], "example.csv", {
            type: "text/csv",
          });

          file.value = exampleCsvFile;
          noFileSelectedError.value = false;
        } catch (error) {
          console.error("Error fetching or using example CSV:", error);
          error.value = true;
          message.value =
            "Failed to load and use the example CSV. Please try again later.";
        }
      }
    }

    async function loadDAresults() {
      try {
        const response = await axios.get(
          "/analysis/history/by-module/data_anonymization/full"
        );
        daResultList.value = response.data;
      } catch (err) {
        console.error(err);
        error.value = true;
        message.value =
          "Failed to load data anonymization results. Please try again later.";
      }
    }

    onMounted(async () => {
      await loadDAresults();
    });

    return {
      showAlert,
      file,
      daResultList,
      loadingBasic,
      loadingAnonymise,
      noFileSelectedError,
      error,
      message,
      isAnyLoading,
      isFileSelected,
      readFile,
      fileInput,
      anonimizeAll,
      customIds,
      submitFile,
      handleFileSelection,
      usingExampleCsv,
      useExampleCsv,
      loadDAresults,
    };
  },
};
</script>
