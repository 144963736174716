<template>
    <form class="needs-validation" novalidate v-on:submit.prevent>
      <div
        class="modal fade"
        id="newVaptModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        >
          <div class="modal-content bg-dark">
            <div class="modal-header border-color-dark">
              <h5 class="modal-title ms-3">                          <img :src="logo" style="max-height: 2.5rem" /> Create new
                          VAPT analysis</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card soft-background-color p-4 mb-3">
                      <div class="mb-2 text-white">
                        <i class="fa-regular fa-file-lines me-1"></i>
                        Insert paramters
                      </div>
                      <div class="mb-3">
                        <label for="vaptName" class="form-label"
                          >Vapt identifier</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="vapt_name"
                          placeholder="Vapt tester"
                          v-model="vapt_name"
                          required
                        />
                        <div class="invalid-feedback">
                          Please enter a valid VAPT name.
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="target_url" class="form-label"
                          >Target url</label
                        >
                        <select
                        class="form-select"
                        id="target_url"
                        aria-label="Section of the target url"
                        v-model="target_url"
                        required
                      >
                        <option value="https://public-firing-range.appspot.com/">https://public-firing-range.appspot.com/</option>
                      </select>
                        <div class="invalid-feedback">
                          Please select a valid url
                        </div>
                      </div>
                      <div class="row b-3">
                        <div class="col-md mt-3 align-self-center">
                      <input type="checkbox" id="ajax_spider" v-model="ajax_spider">
                      <label for="checkbox">Ajax spider</label>
                    </div>
                    <div class="col-md mt-3 align-self-center">
                      <input type="checkbox" id="active_scan" v-model="active_scan">
                      <label for="checkbox">Active scan</label>
                    </div>
                  </div>
                      <hr class="mt-0 mb-3" />

                      <div class="row mt-3 ms-1 me-1">
                        <div
                          class="alert alert-danger text-white shadow-lg opacity-5"
                          role="alert"
                          v-show="error"
                        >
                         <!--<strong>Error occurred: </strong>{{ message }}--> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  ref="Close"
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  class="btn btn-primary"
                  type="submit"
                  @click="createVapt"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </template>
  
  <script>
  import axios from "axios";
  import logo from "@/assets/img/logos/aiaguard.png";
  export default {
    name: "NewVaptCard",
  
    created() {
      axios.defaults.baseURL = this.$store.state.backendURL;
      axios.defaults.withCredentials = true;
    },
  
    methods: {
      createVapt() {
        console.log("Launch processing", {
              vapt_name: this.vapt_name,
              target_url: this.target_url,
              ajax_spider:false,// this.check_vulnerable_deps,  //this.check_vulnerable_deps,
              active_scan: false, //this.check_code_static, //this.check_code_static,
            })
        let all_valid = this.validate();
        console.log("all valid launching")
        if (all_valid) {
          axios
            .post("/analysis/run/vapt", {
              vapt_name: this.vapt_name,
              target_url: this.target_url,
              ajax_spider: this.ajax_spider,//this.vulnerable_deps_engine,
              active_scan: this.active_scan, //this.code_static_engine
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then(() => {
              this.error = false;
              this.$refs.Close.click();
              this.$parent.loadVapt();
            })
            .catch((error) => {
              console.log(error);
              this.error = true;
              //this.message = error.response.statusText;
            });
        } else {
          this.error = false;
        }
      },
  
      validate() {
        
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.querySelectorAll(".needs-validation");
        let all_valid = true;
        
        // Loop over them and prevent submission
        Array.from(forms).forEach((form) => {
         
              if (!form.checkValidity()) {
                all_valid = false;
              }
  
              form.classList.add("was-validated");
            
        });
        
        return all_valid;
      },
    },
  
    data() {
      return {
        error: false,
        error_message: "",
        is_advanced_metrics: false,
        is_random_seed: false,
        logo,
        dataset: "",
        model: "",
        model_options: [],
        target_url: "",
        ajax_spider:true,  //this.check_vulnerable_deps,
        active_scan: false,
        vapt_name: ""
      };
    },
  
    computed: {
      getDatasetUrl() {
        return this.dataset_options.find((option) => option.id === this.dataset)
          .url;
      },
      getModelUrl() {
        return this.model_options.find((option) => option.id === this.model).url;
      },
    },
  
    watch: {
      dataset: function () {
        this.model_options = [];
        if (this.dataset > 0) {
          this.model_options = this.model_pairs[this.dataset];
        }
      },
    },
  };
  </script>
  