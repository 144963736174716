<template>
  <div class="container-fluid mt-4 min-vh-75">
    <div class="mt-4 page-header min-height-200 height-lg-100 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved3.jpg') + ')',
      backgroundPositionY: '80%',
    }">
      <span class="mask bg-gradient-dark opacity-7"></span>
    </div>
    <div class="mx-4 overflow-hidden card blur shadow-blur shadow-lg opacity-8 mt-n5">
      <div class="row gx-4 my-2">
        <div class="col-auto">
          <router-link to="/ast">
            <i class="fa-solid fa-arrow-left text-dark py-3 ms-4"></i>
          </router-link>
        </div>
        <div class="col-auto my-auto">
          <input v-if="edit" type="text" id="ast_name" class="my-auto mr-3" :placeholder="ast.config.ast_name"
            v-model="ast_name" />
          <h6 v-else class="my-auto mr-3 font-weight-bold text-dark">
            {{ ast.config.ast_name }}
          </h6>
        </div>
        <div class="col d-flex justify-content-end">
          <div v-if="edit" class="btn mx-4 my-auto" @click="editAst"><i class="fa-solid fa-save text-dark"></i></div>
          <div v-else class="btn mx-4 my-auto" @click="editAst"><i class="fa-solid fa-pen-to-square text-dark"></i>
          </div>
          <div class="btn me-4 my-auto" @click="deleteAst"><i class="fa-regular fa-trash-can text-dark"></i></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mt-3 d-flex">
        <div class="card card-body p-4">
          <h6>Ast info</h6>

          <hr class="mt-0 mb-2" />
          <ul class="list-group">
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Repository used:</strong> &nbsp;
              {{ ast.config.git_url }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Code static engine:</strong>
              &nbsp; {{ ast.config.code_static_engine }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Vulnerabe deps engine:</strong>
              &nbsp; {{ ast.config.vulnerable_deps_engine }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Check vulnerable_deps:</strong>
              &nbsp; {{ getCheckVulnerableDeps }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Check code static:</strong>
              &nbsp; {{ getCheckCodeStatic }}
            </li>
            <div class="row">
              <div class="col-auto">
                <li class="pt-0 pb-0 text-sm text-white border-0 list-group-item ps-0 my-auto">
                  <strong class="text-light text-bold">Status:</strong> &nbsp;
                  {{ ast.status }}
                </li>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div v-if="ast.config.check_code_static === true && ast.result !== null"
        class="card card-body m-3 p-4 min-height-100 text-sm">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h6>Code static analysis result</h6>
          <div class="d-flex align-items-center">
            <label for="filterSelect" class="mb-0 me-2">Filter:</label>
            <select id="filterSelect" v-model="currentFilter" @change="setFilterOption(currentFilter)"
              class="form-select form-select-sm" style="min-width: 150px;"> <!-- Adjusted the width here -->
              <option v-for="option in filterOptions" :value="option.value" :key="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <hr class="mt-2 mb-2" />
        <div v-if="!ast.result.static_code_analysis.length">
          No error
        </div>
        <div v-for="(result, index) in sortedAndFilteredResults" :key="index"
          class="card card-body bg-dark text-dark text-xs font-weight-bold pt-3 pb-0 mb-1 blur shadow-lg opacity-8">
          <div class="row">
            <div class="col-md-12 mb-3 d-flex justify-content-start">
              <div style="width: 40px; min-width: 40px">{{ result.n }}</div>
              <div class="text-uppercase font-weight-bolder">
                <span class="badge badge-sm bg-secondary" v-html="result.details"></span>
                <i class="fa-solid my-auto mx-2 fa-arrow-right"></i>
                <span class="badge badge-sm bg-secondary" v-html="result.severity"></span>
                <pre class="pt-2"><code v-html="result.code"></code></pre> 
                <div> Filename:
                  <span v-html="result.filename"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="ast.config.check_vulnerable_deps === true && ast.result !== null"
        class="card card-body m-3 p-4 min-height-100 text-sm">
        <h6>Vulenerable dependcies</h6>
        <hr class="mt-0 mb-2" />
        <div v-if="!ast.result.check_vulnerable_deps">
          No error
        </div>
        <div v-for="(result, index) in ast.result.vulnerable_deps" :key="index"
          class="card card-body bg-dark text-dark text-xs font-weight-bold pt-3 pb-0 mb-1 blur shadow-lg opacity-8">
          <div class="row">
            <div class="col-md-12 mb-3 d-flex justify-content-start">
              <div style="width: 40px; min-width: 40px">{{ result.n }}</div>
              <div class="text-uppercase font-weight-bolder">Dependency
                <span class="badge badge-sm bg-secondary" v-html="result.name">
                </span>
                <div>
                  Version
                  <span class="badge badge-sm bg-secondary" v-html="result.version"></span>
                </div>
                <div v-for="(res, index) in result.vulns" :key="index">
                  Summary
                  <span class="badge badge-sm bg-secondary" v-html="res.summary">
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "AstResult",
  data() {
    return {
      edit: false,
      currentFilter: 'high-to-low', // default filter
      filterOptions: [
        { value: 'high-to-low', text: 'High to Low' },
        { value: 'low-to-high', text: 'Low to High' },
        { value: 'only-high', text: 'Only High' },
        { value: 'only-medium', text: 'Only Medium' },
        { value: 'only-low', text: 'Only Low' },
      ],
      // Data about attack read from API
      ast: {
        config: {
          ast_name: "",
          git_url: "",
          check_vulnerable_deps: true,
          check_code_static: true,
          vulnerable_deps_engine: "pipaudit",
          code_static_engine: "bandit"
        },
        result: {
          vulnerable_deps: [],
          static_code_analysis: []
        },
        status: "",
        error: null,
        warnings: null,
        running: false,
        date_start: "",
        date_end: "",
      },
    };
  },

  methods: {
    editAst() {
      // invert boolean value
      if (this.edit) {
        this.edit = false
        //console.log(String(this.ast_name))
        axios
          .put("/analysis/history/update/ast/" + this.id + "?ast_name=" + this.ast_name)
          .then((response) => {
            this.ast = response.data;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
          })
      } else {
        this.edit = true
      }
    },
    deleteAst() {
      axios
        .delete("/analysis/history/delete/" + this.id)
        .then((response) => {
          console.log(response.status);
          this.$router.push("/ast")
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
    },
    loadAstAnalysis() {
      console.log("load analysis", this.id);
      axios
        .get("/analysis/history/" + this.id + "/full")
        .then((response) => {
          //console.log("ok loading");
          this.ast = response.data;
          //console.log(this.ast);
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          //console.log(this.ast);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },

  computed: {
    sortedAndFilteredResults() {
      if (!this.ast.result.static_code_analysis) {
        return [];
      }

      const severityOrder = {
        'HIGH': 3,
        'MEDIUM': 2,
        'LOW': 1
      };

      let results = [...this.ast.result.static_code_analysis];

      if (this.currentFilter.includes('only-')) {
        const severity = this.currentFilter.split('-')[1].toUpperCase();
        results = results.filter(result => result.severity.toUpperCase() === severity);
      }
      if (this.currentFilter === 'high-to-low') {
        results.sort((a, b) => severityOrder[b.severity.toUpperCase()] - severityOrder[a.severity.toUpperCase()]);
      } else if (this.currentFilter === 'low-to-high') {
        results.sort((a, b) => severityOrder[a.severity.toUpperCase()] - severityOrder[b.severity.toUpperCase()]);
      }

      return results;
    },

    getAction() {
      if (this.ast.running) {
        return 'Stop <i class="fa-solid fa-stop ms-2"></i> ';
      } else {
        return 'Run<i class="fa-solid fa-play ms-2"></i>';
      }
    },
    getCheckVulnerableDeps() {
      if (this.ast.config.check_vulnerable_deps) {
        return "Yes";
      } else {
        return "No";
      }
    },
    getCheckCodeStatic() {
      if (this.ast.config.check_code_static) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },

  components: {
    //SoftProgress,
  },
  created() {
    this.id = this.$route.params.id;
    this.loadAstAnalysis();
  },

  mounted() {
    setTooltip();

    //Probe the server for the analysis every 10 seconds:
    this.interval = setInterval(() => {
      this.loadAstAnalysis();
    },
      10000
    );
  },

  //On component deletion:
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
  