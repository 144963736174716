<template>

  <div class="py-4 container-fluid">

    <div class="row">
      <div class="col-lg-6 mb-lg-0 mb-4">
        <div class="card  mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12  ms-auto  mt-5 mt-lg-0">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">Data anonymization module</p>
                  <router-link to="/data-anonymization" custom v-slot="{ navigate }">
                  <h5 class="font-weight-bolder" @click="navigate">Data anonymization</h5>
                  </router-link>
                  <p class="mb-5">
                    Upload and analyse your dataset to identify sensitive and personal information
                  </p>
                  <div>
                    <router-link to="/data-anonymization" custom v-slot="{ navigate }">
                    <button type="button" class="btn btn-primary">
                      <div class="mb-0 icon-move-right mt-auto" @click="navigate"
                        role="link">
                        Run analysis

                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                      </div>
                      </button>
                    </router-link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
            <div class="col-lg-6 mb-lg-0 mb-4">
        <div class="card mb-4">
          <div class="card-body">
            <div class="col-lg-12 ms-auto  mt-5 mt-lg-0">
              <div class="d-flex flex-column h-100">
                <p class="mb-1 pt-2 text-bold">NLP adversarial attack</p>
                <router-link to="/adversarial-attack" custom v-slot="{ navigate }">
                <h5 class="font-weight-bolder" @click="navigate">Adversarial attack</h5>
                 </router-link>
                <p class="mb-5">
                  Run the adversarial attack module to generate new samples to make your model more robust.
                </p>
                <div>
                  <router-link to="/adversarial-attack" custom v-slot="{ navigate }">
                  <button type="button" class="btn btn-primary">
                    <div class="mb-0 icon-move-right mt-auto" @click="navigate"
                      role="link">
                      Run analysis
                      <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                    </div>
                    </button>
                  </router-link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
            <div class="col-lg-6 mb-lg-0 mb-3">
        <div class="card  mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">Interpretability</p>
                  <router-link to="/interpretability" custom v-slot="{ navigate }">
                  <h5 class="font-weight-bolder" @click="navigate">Interpretability</h5>
                  </router-link>
                  <p class="mb-5">
                    Analyse your model to explain the prediction outcome
                  </p>
                  <div>
                    <router-link to="/interpretability" custom v-slot="{ navigate }">
                    <button type="button" class="btn btn-primary">
                      <div class="mb-0 icon-move-right mt-auto" @click="navigate"
                        role="link">
                        Run analysis

                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                      </div>
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            <div class="col-lg-6 mb-lg-0 mb-3">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">Vulnerability assessment & Penetration testing</p>
                  <router-link to="/vapt" custom v-slot="{ navigate }">
                  <h5 class="font-weight-bolder" @click="navigate">Vapt</h5>
                  </router-link>
                  <p class="mb-5">
                    Run Vapt test on your solution to identify potential weakness.
                  </p>
                  <div>
                    <router-link to="/vapt" custom v-slot="{ navigate }">
                    <button type="button" class="btn btn-primary">
                      <div class="mb-0 icon-move-right mt-auto" @click="navigate"
                        role="link">
                        Run analysis

                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                      </div>
                      </button>
                    </router-link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-lg-0 mb-3">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">Static code analysis</p>
                  <router-link to="/ast" custom v-slot="{ navigate }">
                  <h5 class="font-weight-bolder" @click="navigate">Ast</h5>
                  </router-link>
                  <p class="mb-5">
                    Analyse your source code to identify vulnerabilities.
                  </p>
                  <div>
                    <router-link to="/ast" custom v-slot="{ navigate }">
                    <button type="button" class="btn btn-primary">
                      <div class="mb-0 icon-move-right mt-auto" @click="navigate"
                        role="link">
                        Run analysis

                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                      </div>
                      </button>
                    </router-link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div> 


    </div>


  </div>
</template>
<script>

import axios from "axios";

export default {
  name: "dashboard-default",
  data() {
    return {
      analysis: {
        status: "",
        date_start: "",
        date_end: "",
        module: ""
      },
      ast: [],
      iconBackground: "bg-gradient-primary"
    };
  },
  methods: {
    loadAst() {
      axios
        .get('analysis/history/by-module/ast')
        .then(response => {
          this.ast = response.data
        })
        .catch(error => {
          console.log(error)
          this.errored = true
          this.error_message = error.message
          this.status = error.response.status
          this.$refs.Alert.click()
        })
        .finally(() => this.loading = false)
    },
  },
  components: {
    // MiniStatisticsCard,
  },
};
</script>
