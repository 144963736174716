<template>
  <div class="container-fluid min-vh-85">
    <div class="mt-4 page-header min-height-300 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
      backgroundPositionY: '50%',
    }">
      <span class="mask bg-gradient-dark opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4 align-items-center">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img src="@/assets/img/avatar.jpg" alt="profile_image" class="shadow-sm w-100 border-radius-lg" />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1 text-dark">User account</h5>
            <p class="mb-0 text-sm font-weight-bold text-secondary">{{ getUser() }}</p>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <button class="btn btn-danger" @click="deleteProfile">Delete My Profile</button>
        </div>
        </div>
        <!--
        <div class="col text-end">
          <button class="btn btn-primary" @click="downloadAnalysis">Download All Analysis</button>
        </div>
        -->
       <div class="row mt-3">
       <div class="alert alert-danger text-white shadow-lg opacity-5" role="alert" v-show="error">
          <strong>Error occurred:</strong> {{ message }}
        </div>
        </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
export default {
  name: "ProfileOverview",
  data() {
    return {
        error: false,
        message: '',
        showMenu: false,
    };
  },
  methods: {
    getUser() {
      if (!this.$store.getters.getLoginUserInfo) {
        return "";
      } else {
        return this.$store.state.loginUser.email;
      }
    },
    deleteProfile() {
      if (confirm("Are you sure you want to delete your profile and all related analysis? This action cannot be undone.")) {
          console.log("Profile deletion initiated");
          axios
            .delete("/users/me")
            .then((response) => {
              console.log(response.status);
              this.$router.push('/sign-up');
            })
            .catch((error) => {
              console.log(error);
              this.error = true;
              if (error.response && error.response.data) {
                this.message = error.response.data.detail || "An error occurred, but the server did not provide further details.";
              } else if (error.message) {
                this.message = error.message;
              } else {
                this.message = "An unexpected error occurred.";
             }
            })
      }
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
