<template>
  <div class="container-fluid mt-4 min-vh-75">
    <div class="mt-4 page-header min-height-200 height-lg-100 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved3.jpg') + ')',
      backgroundPositionY: '80%',
    }">
      <span class="mask bg-gradient-dark opacity-7"></span>
    </div>
    <div class="mx-4 overflow-hidden card blur shadow-blur shadow-lg opacity-8 mt-n5">
      <div class="row gx-4 my-2">
        <div class="col-auto">
          <router-link to="/adversarial-attack">
            <i class="fa-solid fa-arrow-left text-dark py-3 ms-4"></i>
          </router-link>
        </div>
        <div class="col-auto my-auto">
          <input v-if="edit" type="text" id="attack_name" class="my-auto mr-3" :placeholder="attack.config.attack_name"
            v-model="attack_name" />
          <h6 v-else class="my-auto mr-3 font-weight-bold text-dark">
            {{ attack.config.attack_name }}
          </h6>
        </div>
        <div class="col d-flex justify-content-end">
          <div v-if="edit" class="btn mx-4 my-auto" @click="editAttack">
            <i class="fa-solid fa-save text-dark"></i>
          </div>
          <div v-else class="btn mx-4 my-auto" @click="editAttack">
            <i class="fa-solid fa-pen-to-square text-dark"></i>
          </div>
          <div class="btn me-4 my-auto" @click="deleteAttack">
            <i class="fa-regular fa-trash-can text-dark"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 mt-3 d-flex">
        <div class="card card-body p-4">
          <h6>Attack info</h6>

          <hr class="mt-0 mb-2" />
          <ul class="list-group">
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Attacked model:</strong>
              &nbsp; {{ attack.config.attacked_model }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Dataset used:</strong> &nbsp;
              {{ attack.config.dataset_used
              }}{{
      attack.config.dataset_subset_used !== null
        ? "/".concat(attack.config.dataset_subset_used)
        : null
    }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Samples under attack:</strong>
              &nbsp; {{ attack.config.samples_under_attack }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Type of attack:</strong>
              &nbsp; {{ attack.config.type_of_attack }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Random seed:</strong>
              &nbsp; {{ getRandomSeed }}
            </li>
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Advanced metrics:</strong>
              &nbsp; {{ getAdvancedMetrics }}
            </li>
            <div class="row">
              <div class="col-auto">
                <li class="pt-0 pb-0 text-sm text-white border-0 list-group-item ps-0 my-auto">
                  <strong class="text-light text-bold">Status:</strong> &nbsp;
                  {{ attack.status }}
                </li>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 mt-3 d-flex">
        <div class="card card-body p-4">
          <h6>Attack report</h6>

          <hr class="mt-0 mb-2" />
          <ul class="list-group">
            <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
              <strong class="text-light text-bold">Succ/Fail/Skip:</strong>
              &nbsp; {{ attack.output.successful_attacks }} /
              {{ attack.output.failed_attacks }} /
              {{ attack.output.skipped_attacks }}
            </li>
            <div v-if="attack.status == 'Error'">
              <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                <strong class="text-light text-bold">Error message:</strong>
                {{ attack.error }}
              </li>
            </div>
            <div v-else-if="attack.status === 'Completed'">
              <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                <strong class="text-light text-bold">Original vs attacked accuracy:</strong>
                &nbsp; {{ attack.result.original_accuracy }}% /
                {{ attack.result.attacked_accuracy }}%
              </li>
              <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                <strong class="text-light text-bold">Attack success rate:</strong>
                &nbsp;
                {{ attack.result.attack_success_rate }}%
              </li>
              <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                <strong class="text-light text-bold">Perturbed words:</strong>
                &nbsp; {{ attack.result.avg_word_perturbed_perc }}%
              </li>
              <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                <strong class="text-light text-bold">Avg num words per input:</strong>
                &nbsp; {{ attack.result.avg_word_input }}
              </li>
              <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                <strong class="text-light text-bold">Avg num queries per attack:</strong>
                &nbsp; {{ attack.result.avg_num_queries }}
              </li>
              <div v-if="attack.config.is_advanced_metrics">
                <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                  <strong class="text-light text-bold">Avg attack time:</strong>
                  &nbsp; {{ attack.result.avg_attack_time }} s
                </li>

                <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                  <strong class="text-light text-bold">Avg query time:</strong>
                  &nbsp; {{ attack.result.avg_query_time }} s
                </li>
                <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                  <strong class="text-light text-bold">Avg semantic similarity:</strong>
                  &nbsp; {{ attack.result.avg_sentence_bert_similarity }}
                </li>
                <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                  <strong class="text-light text-bold">Original vs attacked perplexity:</strong>
                  &nbsp; {{ attack.result.avg_original_perplexity }} /
                  {{ attack.result.avg_attack_perplexity }}
                </li>
                <li class="pt-0 text-sm text-white border-0 list-group-item ps-0">
                  <strong class="text-light text-bold">Contradiction rate:</strong>
                  &nbsp; {{ attack.result.attack_contradiction_rate }}%
                </li>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <div v-if="attack.status === 'Completed'" class="card card-body m-3 p-4 min-height-100 text-sm">
        <h6>Attack results</h6>
        <hr class="mt-0 mb-2" />

        <div
          class="card card-body bg-dark text-uppercase text-white text-xs font-weight-bolder pt-3 pb-0 mb-1 shadow-lg">
          <div class="row">
            <div class="col-md-2 mb-3 d-flex justify-content-start">
              <div style="width: 40px; min-width: 40px">#</div>
              Output
            </div>
            <div class="col-md-5 mb-3 justify-content-start">Original text</div>
            <div class="col-md-5 mb-3 justify-content-start">Attacked text</div>
          </div>
        </div>
        <div v-for="(result, index) in attack.result.results" :key="index"
          class="card card-body bg-dark text-dark text-xs font-weight-bold pt-3 pb-0 mb-1 blur shadow-lg opacity-8">
          <div class="row">
            <div class="col-md-2 mb-3 d-flex justify-content-start">
              <div style="width: 40px; min-width: 40px">{{ result.n }}</div>
              <div class="text-uppercase font-weight-bolder">
                <span class="badge badge-sm bg-light" v-html="result.original_output">
                </span>
                <i class="fa-solid my-auto mx-2 fa-arrow-right"></i>
                <span class="badge badge-sm bg-light" v-html="result.attacked_output"></span>
              </div>
            </div>
            <div class="col-md-5 mb-3 justify-content-start" v-html="result.original_text"></div>
            <div class="col-md-5 mb-3 justify-content-start" v-html="result.attacked_text"></div>
          </div>
          <div class="col-md-12 d-flex justify-content-end mt-2">
            <button class="btn btn-secondary btn-sm" @click="copyResultToClipboard(result)">Copy Result</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "AdversarialAttackResult",

  data() {
    return {
      edit: false,
      // Data about attack read from API
      attack: {
        config: {
          attack_name: "",
          attacked_model: "",
          dataset_used: "",
          dataset_subset_used: "",
          samples_under_attack: 0,
          query_budget: 0,
          is_advanced_metrics: false,
          type_of_attack: "",
        },
        output: {
          successful_attacks: 0,
          failed_attacks: 0,
          skipped_attacks: 0,
        },
        result: {
          original_accuracy: 0,
          attacked_accuracy: 0,
          attack_success_rate: 0,
          avg_word_perturbed_perc: 0,
          avg_word_input: 0,
          avg_num_queries: 0,
          avg_attack_time: 0,
          avg_query_time: 0,
          avg_sentence_bert_similarity: 0,
          avg_original_perplexity: 0,
          avg_attack_perplexity: 0,
          attack_contradiction_rate: 0,
          results: [],
        },
        status: "",
        error: null,
        warnings: null,
        running: false,
        date_start: "",
        date_end: "",
      },
    };
  },

  methods: {
    editAttack() {
      // invert boolean value
      if (this.edit) {
        this.edit = false;
        //console.log(String(this.attack_name));
        axios
          .put(
            "/analysis/history/update/" +
            this.id +
            "?attack_name=" +
            this.attack_name
          )
          .then((response) => {
            this.attack = response.data;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
          });
      } else {
        this.edit = true;
      }
    },
    deleteAttack() {
      axios
        .delete("/analysis/history/delete/" + this.id)
        .then((response) => {
          console.log(response.status);
          this.$router.push("/adversarial-attack");
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    loadAdversarialAttack() {
      axios
        .get("/analysis/history/" + this.id + "/full")
        .then((response) => {
          this.attack = response.data;
          //console.log(this.attack);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getOutput(result) {
      return (
        "<span class='badge badge-sm  bg-dark>" +
        +result.original_output +
        "</span>" +
        '<i class="fa-solid my-auto mx-2 fa-arrow-right"></i>' +
        "<span class='badge badge-sm bg-dark>" +
        result.attacked_output +
        "</span>"
      );
    },

    async copyResultToClipboard(result) {
      function stripHtml(html) {
        var temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
      }
      const attackedText = stripHtml(result.attacked_text);
      const textToCopy = `${attackedText}`;
      try {
        await navigator.clipboard.writeText(textToCopy);
        alert('Results copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy results: ', err);
        alert('Failed to copy results.');
      }
    },


    getColor(output) {
      if (output == "Pos") {
        return "bg-gradient-success";
      } else if (output == "Neg") {
        return "bg-gradient-danger";
      } else {
        return "bg-gradient-secondary";
      }
    },
  },

  computed: {
    getAction() {
      if (this.attack.running) {
        return 'Stop <i class="fa-solid fa-stop ms-2"></i> ';
      } else {
        return 'Run<i class="fa-solid fa-play ms-2"></i>';
      }
    },
    getAdvancedMetrics() {
      if (this.attack.config.is_advanced_metrics) {
        return "Yes";
      } else {
        return "No";
      }
    },
    getRandomSeed() {
      if (this.attack.config.is_random_seed) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },

  components: {
    //SoftProgress,
  },

  created() {
    this.id = this.$route.params.id;
    this.loadAdversarialAttack();
  },

  mounted() {
    setTooltip();

    //Probe the server for the analysis every 10 seconds:
    this.interval = setInterval(() => {
      this.loadAdversarialAttack();
    }, 10000);
  },

  //On component deletion:
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
