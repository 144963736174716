<template>
  <div class="container-fluid mt-4 min-vh-75">
    <div class="mt-4 page-header min-height-200 height-lg-100 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved3.jpg') + ')',
      backgroundPositionY: '80%',
    }">
      <span class="mask bg-gradient-dark opacity-7"></span>
    </div>
    <div class="mx-4 overflow-hidden card blur shadow-blur shadow-lg opacity-8 mt-n5">
      <div class="row gx-4 my-2">
        <div class="col-auto">
          <router-link to="/data-anonymization">
            <i class="fa-solid fa-arrow-left text-dark py-3 ms-4"></i>
          </router-link>
        </div>
        <div class="col-auto my-auto">
          <h6 class="my-auto mr-3 font-weight-bold text-dark">
            {{ daResult.config.original_csv_name }}
          </h6>
        </div>
        <div class="col d-flex justify-content-end">
          <div class="btn me-4 my-auto" @click="deleteDaResult">
            <i class="fa-regular fa-trash-can text-dark"></i>
          </div>
          <div v-if="daResult.config.analysis_type == 'anonymise'">
            <div class="btn me-4 my-auto" @click="downloadCSV(outputFileName)" v-if="!isDateOlderThanMinutes()"
              title="File download">
              <i class="fa-solid fa-download text-dark"></i>
            </div>
            <div v-else class="btn me-4 my-auto" title="File download expired">
              <i class="fa-solid fa-triangle-exclamation text-dark"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="daResult.status === 'Completed' & daResult.config.analysis_type == 'basic'"
      class="card card-body m-3 p-4 min-height-100 text-sm">
      <h6>Data scan results</h6>
      <hr class="mt-0 mb-2" />
      <div class="row">
        <div class="col-12">
          <h6>Problematic columns:</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Column Name</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(type, columnName) in daResult.result" :key="columnName">
                  <td>{{ columnName }}</td>
                  <td>{{ type }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card card-body m-3 p-4 min-height-100 text-sm">
      <h6>Data anonymisation result</h6>
      <hr class="mt-0 mb-2" />
      <div class="row">
        <div class="col-12">
          <h6>{{ daResult.status }} at {{ format_date(daResult.date_end) }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table-responsive {
  overflow-x: auto;
}
</style>

<script>
import axios from "axios";
import setTooltip from "@/assets/js/tooltip.js";
import moment from 'moment'

export default {
  name: "DataAnonymizationResult",

  data() {
    return {
      edit: false,
      outputFileName: '',
      // Data about csv analysis read from API
      daResult: {
        config: {
          original_csv_name: "",
          analysis_type: ""
        },
        output: {

        },
        result: [],
        status: "",
        error: null,
        warnings: null,
        running: false,
        date_start: "",
        date_end: new Date()
      },
    };
  },

  methods: {

    format_date(value) {
      if (value) {
        return moment(String(value)).format('HH:mm DD/MM/YYYY')
      }
    },

    deleteDaResult() {
      axios
        .delete("/analysis/history/delete/" + this.id)
        .then((response) => {
          console.log(response.status);
          this.$router.push("/data-anonymization")
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
    },
    loadDaResult() {
      axios
        .get("/analysis/history/" + this.id + "/full")
        .then((response) => {
          this.daResult = response.data;
          this.outputFileName = response.data.output.file
          //console.log(this.daResult);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    clearOldSavedFiles() {
      axios
        .post("/analysis/file/clear")
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
    },

    isDateOlderThanMinutes() {

      var date = new Date();
      var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
      const currentDateTime = new Date(now_utc);
      const stringDate = (String(this.daResult.date_end) + 'Z');
      const endDateTime = new Date(stringDate);
      const diffMilliseconds = Math.abs(endDateTime.getTime() - currentDateTime.getTime());
      const diffInMinutes = Math.floor(diffMilliseconds / 60000);
      return diffInMinutes > 10;
    },
    downloadCSV(fileName) {
      axios
        .post("/analysis/file/download?filename=" + fileName, { responseType: 'blob' })
        .then((response) => {
          //console.log(response.data);
          const blob = new Blob([response.data]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
    }
  },

  components: {
    //SoftProgress,
  },

  created() {
    this.id = this.$route.params.id;
    this.loadDaResult();
  },

  mounted() {
    setTooltip();

    //Probe the server for the analysis every 30 seconds:
    this.interval = setInterval(() => {
      this.clearOldSavedFiles();
      this.loadDaResult();
    },
      30000
    );
  },

  //On component deletion:
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>