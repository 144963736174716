<template>
  <div v-if="!isDeleted" class="btn card mb-3 shadow"
    :class="{ disabled: numFilthyColumns == 0 && status === 'Completed' && analysis_type === 'Basic' }">
    <div class="row d-flex align-items-center">
      <div class="col text-white" @click="navigateToResult(id)">
        {{ fileName }}
      </div>
      <div class="col text-white" v-if="numFilthyColumns" @click="navigateToResult(id)">
        Problematic columns:
        <span class="text-danger">{{ numFilthyColumns }}</span>
      </div>
      <div class="col text-white" v-else @click="navigateToResult(id)">
        <div v-if="analysis_type.toLowerCase() == 'basic'">
          <span class="text-success" v-if="status == 'Completed'">
            No problematic values found
          </span>
          <span v-else class="text-danger">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="col" @click="navigateToResult(id)">
        {{ analysis_type }}
      </div>
      <div class="col" @click="navigateToResult(id)">
        {{ getStartDate }}
      </div>
      <div class="col p-0" @click="navigateToResult(id)">
        <div class="btn m-0">{{ status }}</div>
      </div>
      <div class="col-auto" style="width: 100px;">
        <button class="btn me-4 my-auto text-white delete-icon" @click.stop="deleteDaResult" title="Delete analysis">
          <i class="fa-regular fa-trash-can"></i>
        </button>
        <button v-if="analysis_type.toLowerCase() === 'anonymise' && showDownloadButton()"
          class="btn me-4 my-auto text-white" @click.stop="downloadCSV(outputFileName)" title="Download file">
          <i class="fa-solid fa-download"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import "@/assets/css/DataAnonymizationStyles.css";

export default {
  name: "DataAnonymizationItem",
  props: {
    id: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      default: "",
    },
    outputFileName: {
      type: String,
      default: "",
    },
    numFilthyColumns: {
      type: Number,
      default: 0
    },
    analysis_type: {
      type: String,
      required: true,
      default: ""
    },
    result: {
      type: Object,
      default() {
        return {}
      }
    },
    error: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    date_start: {
      type: String,
      default: "",
    },
        date_end: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isDeleted: false,
    };
  },
  methods: {
    navigateToResult(id) {
      this.$router.push({ name: 'Data anonymization result', params: { id: id } });
    },
    deleteDaResult() {
      if (confirm("Are you sure you want to delete this item?")) {
        axios.delete("/analysis/history/delete/" + this.id)
          .then(() => {
            this.isDeleted = true;
          })
          .catch((error) => {
            console.error(error);
            this.errored = true;
          });
      }
    },
    downloadCSV(fileName) {
      axios.post(`/analysis/file/download?filename=${fileName}`, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
          this.errored = true;
        })
    },
      showDownloadButton() {
    if (this.analysis_type.toLowerCase() !== 'anonymise' || !this.outputFileName) {
      return false;
    }
      var date = new Date();
      var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
      const currentDateTime = new Date(now_utc);
      const stringDate = (String(this.date_end) + 'Z');
      const endDateTime = new Date(stringDate);
      const diffMilliseconds = Math.abs(endDateTime.getTime() - currentDateTime.getTime());
      const diffInMinutes = Math.floor(diffMilliseconds / 60000);
      return diffInMinutes < 10;
  },
  },
  computed: {
    getStartDate() {
      return moment(this.date_start).format("DD/MM/YYYY HH:mm");
    }
  },

};
</script>
