<template>
    <form class="needs-validation" novalidate v-on:submit.prevent>
      <div
        class="modal fade"
        id="newInterpretabilityModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content bg-dark">
            <div class="modal-header border-color-dark">
              <h5 class="modal-title ms-3"><img :src="logo" style="max-height: 2.5rem" />Create new Interpretability analysis</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card soft-background-color p-4 mb-3">
                      <div class="mb-2 text-white">
                        <i class="fa-regular fa-file-lines me-1"></i>
                        Insert parameters
                      </div>
                      <div class="mb-3">
                        <label for="interpretability_name" class="form-label"
                          >Interpretability identifier</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="interpretability_identifier"
                          placeholder="Interpretability tester"
                          v-model="interpretability_identifier"
                          required
                        />
                        <div class="invalid-feedback">
                          Please enter a valid interpretability name.
                        </div>
                      </div>

                      <div class="mb-3">
                        <label for="inputType">Select Input Type</label>

                        <select
                          class="form-select"
                          id="inputType"
                          aria-label="Section of the inpuy type"
                          v-model="input_type"
                          required
                        >
                          <option value="">
                           Choose data input method
                          </option>
                          <option
                            v-for="option in input_selection"
                            :value="option.id"
                            :key="option.id"
                          >
                            {{ option.text }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Please select a valid input type.
                        </div>
                      </div>

                      <div v-if="input_type === 1" class="mb-3">
                        <label for="sentence_used" class="form-label"
                          >Sentence analyzed</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="text_input"
                          placeholder="Textfield for sentence"
                          v-model="sentence_used"
                          required
                        />
                        <div class="invalid-feedback">
                          Please enter a valid sentence (any text will do).
                        </div>
                      </div>

                      <!-- Text File Input -->
                      <div v-if="input_type === 2" class="mb-3">
                        <label for="text_file_input" class="form-label"
                          >Text File Input (.txt or .docx)</label
                        >
                        <input
                          type="file"
                          class="form-control"
                          id="text_file_input"
                          accept=".txt, .docx"
                          ref="textFileInput"
                          v-on:change="handleFileInputChange"
                          required
                        />
                        <div class="invalid-feedback">
                          Please choose a valid text file.
                        </div>
                      </div>

                      <!-- Image File Input -->
                      <div v-if="input_type === 3" class="mb-3">
                        <label for="image_file_input" class="form-label">Image File Input (.png or .jpg)</label>
                        <input type="file" class="form-control" id="image_file_input" accept=".png, .jpg" @change="handleFileChange">
                        <div class="invalid-feedback" v-if="invalidFile">Please choose a valid image file.</div>
                      </div>  
                      <hr class="mt-0 mb-2" />
                      <div class="mb-2 text-white">
                        <i class="fa-solid fa-gears"></i>
                        Model type
                      </div>
                      <div v-if="input_type === 1 || input_type === 2" class="mb-3">
                        <label for="analysisType" class="form-label"
                          >Type of text analysis</label
                        >
                        <select
                          class="form-select"
                          id="analysisType"
                          aria-label="Section of the analysis type"
                          v-model="analysis_type"
                          required
                        >
                          <option value="">
                           Choose how text will be analyzed
                          </option>
                          <option
                            v-for="option in analysis_selection"
                            :value="option.id"
                            :key="option.url"
                          >
                            {{ option.text }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Please select a valid analysis type.
                        </div>
                      </div>
                      <div v-if="input_type === 3" class="mb-3">
                        <label for="modelName" class="form-label"
                          >Model used for analysis</label>
                        <select
                          class="form-select"
                          id="modelName"
                          aria-label="Section of the model"
                          v-model="model"
                          required
                        >
                          <option value="">
                           Choose a model to be analyzed
                          </option>
                          <option
                            v-for="pair in img_model_options"
                            :value="pair.id"
                            :key="pair.url"
                          >
                            {{ pair.text }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Please select a model to be analyzed.
                        </div>
                      </div>
                      <div v-if="input_type === 3" class="mb-3">
                        <label for="fidelity" class="form-label"
                          >Fidelity used</label>
                        <select
                          class="form-select"
                          id="fidelity"
                          aria-label="Section of the fidelity"
                          v-model="image_fidelity"
                          required
                        >
                          <option value="">
                           Choose a fidelity at which image classification is analyzed
                          </option>
                          <option
                            v-for="pair in image_detail"
                            :value="pair.id"
                            :key="pair.url"
                          >
                            {{ pair.text }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Please select a fidelity for analysis.
                        </div>
                      </div>
                      <div v-if="input_type === 1 || input_type === 2" class="mb-3">
                        <label for="modelName" class="form-label"
                          >Model used for analysis</label
                        >
                        <select
                          :disabled="!analysis_type"
                          class="form-select"
                          id="modelName"
                          aria-label="Section of the model"
                          v-model="model"
                          required
                        >
                          <option value="">
                           Choose a model to be analyzed
                          </option>
                          <option
                            v-for="pair in model_options"
                            :value="pair.id"
                            :key="pair.url"
                          >
                            {{ pair.text }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Please select a valid model.
                        </div>
                      </div>
                      <hr class="mt-0 mb-3" />
                      <div class="row mt-3 ms-1 me-1">
                        <div
                          class="alert alert-danger text-white shadow-lg opacity-5"
                          role="alert"
                          v-show="error"
                        >
                         <!--<strong>Error occurred: </strong>{{ message }}--> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  ref="Close"
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  class="btn btn-primary"
                  type="submit"
                  @click="createInterpretability"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
</template>

<script>
import axios from "axios";
import logo from "@/assets/img/logos/aiaguard.png";
export default {
  name: "NewInterpretabilityCard",
  
  created() {
    axios.defaults.baseURL = this.$store.state.backendURL;
    axios.defaults.withCredentials = true;
  },
  
  methods: {    
    async createInterpretability() {

      let all_valid = this.validate();
      console.log("all valid launching")
      
      if (this.input_type === 2) {
          try {
            this.sentence_used = await this.processTextFile();
          } catch (error) {
            console.error("Error processing text file:", error);
          return;
          }
      }
      if (this.input_type === 3) {
          try {
            this.imageUrl = await this.uploadImage();
          } catch (error) {
            console.error("Error processing image file:", error);
          return;
          }
      }
      
      if (all_valid) {
        let requestData = {
          interpretability_name: this.interpretability_identifier,
          analysis_selected: this.analysis_type,
          input_selection: this.input_type,
          sentence_analyzed: this.sentence_used,
          model_used: this.getModelUrl,
        };
        if (this.imageUrl !== null) {
          console.log(this.imageUrl)
          requestData.image_analyzed = this.imageUrl;
          requestData.image_detail = this.image_fidelity;
          console.log(requestData.image_analyzed + requestData.image_detail)
        }
        console.log(requestData)
        try{
          await axios.post("/analysis/run/interpretability", requestData);
              this.error = false;
              this.$refs.Close.click();
              this.$parent.loadInterpretability();
          }
          catch(error) {
              console.log(error);
              this.error = true;
              this.message = error.response.statusText;
          }
        } else {
          this.error = false;
        }
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadImage() {
      const formData = new FormData();
      formData.append('image', this.selectedFile);

      try {
        const response = await axios.post('/analysis/run/upimage', formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Image uploaded successfully:', response.data.path);
        return response.data.path
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    },
       
    async processTextFile() {
      const fileInput = this.$refs.textFileInput;
      return new Promise((resolve, reject) => {
        if (fileInput.files.length > 0) {
          const file = fileInput.files[0];
          this.readTextFile(file)
            .then((sentence_used) => {
              console.log("Text File Content:", sentence_used);
              resolve(sentence_used);
            })
            .catch((error) => {
              console.error("Error reading text file:", error);
              reject(error);
            });
        } else {
          console.error("No text file selected");
          reject(new Error("No text file selected"));
        }
      });
    },
    readTextFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const sentence_used = event.target.result;
          resolve(sentence_used);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsText(file);
      });
    },

    validate() {
      
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      const forms = document.querySelectorAll(".needs-validation");
      let all_valid = true;
      
      // Loop over them and prevent submission
      Array.from(forms).forEach((form) => {
       
            if (!form.checkValidity()) {
              all_valid = false;
            }
            form.classList.add("was-validated");
          
      });
      
      return all_valid;
    },
  },
  
  data() {
    return {
      error: false,
      error_message: "",
      logo,
      image_variable_from_input: null,
      input_selection:[
        {
          text: "Text Field",
          id: 1,
        },
        {
          text: "Text File",
          id: 2,
        },
        {
          text: "Image File",
          id: 3,
        },
      ],
      analysis_selection:[
        {
          text: "Sentiment analysis",
          url: "Sentiment analysis",
          id: 1,
        },
        {
          text: "Classification (topic)",
          url: "Classification",
          id: 2,
        },
      ],
      analysis_type: "",
      input_type: "",
      selectedFile: null,
      imageUrl: "",
      image_fidelity: "",
      sentence_used: "",
      model: "",
      model_options: [],
      img_model_options: [
          {
            text: "ResNet50",
            url: "ResNet50",
            id: 1,
          },
          {
            text: "InceptionV3",
            url: "InceptionV3",
            id: 2,
          },
          {
            text: "VGG16",
            url: "VGG16",
            id: 3,            
          }
      ],
      image_detail: [
        {
          text: "low",
          url: "50",
          id: 50
        },
        {
          text: "medium",
          url: "250",
          id: 250
        },
        {
          text: "high",
          url: "1000",
          id: 1000
        }
      ],
      model_pairs: {
        1: [
          {
            text: "Roberta",
            url: "cardiffnlp/twitter-roberta-base-sentiment-latest",
            id: 1,
          },
          {
            text: "Distilbert",
            url: "lxyuan/distilbert-base-multilingual-cased-sentiments-student",
            id: 2,
          },
          {
            text: "Finbert",
            url: "ProsusAI/finbert",
            id: 3,
          },
        ],
        2: [
          {
            text: "Economy",
            url: "hakonmh/topic-xdistil-uncased",
            id: 1,
          },
          {
            text: "Emotion",
            url: "nateraw/bert-base-uncased-emotion",
            id: 2,
          },
          {
            text: "Hallucination",
            url: "vectara/hallucination_evaluation_model",
            id: 3,            
          }
        ],
      },
    };
  },
  
  computed: {
    getModelUrl() {
      if (this.analysis_type == 1 ||  this.analysis_type == 2)
        return this.model_options.find((option) => option.id === this.model).url
      else
        return this.img_model_options.find((option) => option.id === this.model).url

    },
  },
  
  watch: {
    analysis_type: function () {
      this.model_options = [];
      this.img_model_options = [];
        if (this.analysis_type == 1 ||  this.analysis_type == 2) {
          this.model_options = this.model_pairs[this.analysis_type];
        }
        if (this.analysis_type == 3){
          this.img_model_options = this.input_type;
        }
    },
  },
};
</script>
  