<template>
  <div class="container-fluid mt-4">
    <div class="d-flex justify-content-end">

      <button type="button" class="btn btn-primary ms-auto me-3" @click="loadVaptAnalysis"><i
          class="fa-solid fa-rotate-right"></i></button>
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newVaptModal">New
        run</button>
    </div>

    <div v-if="this.vaptAnalysisList.length == 0" class="row min-vh-65">
      <div class="card text-center mx-auto mt-auto mb-auto w-75">
        <div class="card-body mt-4">
          <p class="card-text">Select a url and run VAPT Analysis</p>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newVaptModal">New
            run</button>
        </div>
      </div>
    </div>
    <div v-else class="min-vh-65">
      <vapt-item v-for="(vapt, index) of this.vaptAnalysisList" :key="index" :id=vapt._id
        :vapt_name=vapt.config.vapt_name :target_url=vapt.config.target_url :status=vapt.status
        :date_start=vapt.date_start />
    </div>

  </div>
  <button ref="Alert" type="button" class="invisible" data-bs-toggle="modal" data-bs-target="#alertModal"></button>
  <NewVaptCard />
  <alert-card :message=error_message :status=status />

</template>
  
<script>
import axios from "axios";

import NewVaptCard from "@/views/Vapt/NewVaptCard.vue";
import VaptItem from "@/views/Vapt/VaptItem.vue";
import AlertCard from "@/components/AlertCard.vue";

export default {
  name: "Vapt",
  components: {
    NewVaptCard,
    VaptItem,
    AlertCard,
  },

  data() {
    return {
      isActive: "active",
      error_message: "",
      errored: false,
      vaptAnalysisList: [],
      status: 200
    };
  },

  methods: {
    loadVapt() {
      axios
        .get('analysis/history/by-module/vapt')
        .then(response => {
          this.vaptAnalysisList = response.data
        })
        .catch(error => {
          console.log(error)
          this.errored = true
          this.error_message = error.message
          this.status = error.response.status
          this.$refs.Alert.click()
        })
        .finally(() => this.loading = false)
    },
  },

  created() {
    this.loadVapt();
  },

  mounted() {

    //Probe the server for the analysis every 10 seconds:
    this.interval = setInterval(() => {
      this.loadVapt();
    },
      10000
    );
  },

  //On component deletion:
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
  