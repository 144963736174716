<template>
    <form class="needs-validation" novalidate v-on:submit.prevent>
      <div
        class="modal fade"
        id="newAstModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        >
          <div class="modal-content bg-dark">
            <div class="modal-header border-color-dark">
              <h5 class="modal-title ms-3"><img :src="logo" style="max-height: 2.5rem" />Create new Ast analysis</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card soft-background-color p-4 mb-3">
                      <div class="mb-2 text-white">
                        <i class="fa-regular fa-file-lines me-1"></i>
                        Insert parameters
                      </div>
                      <div class="mb-3">
                        <label for="ast_name" class="form-label"
                          >Ast identifier</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="ast_name"
                          placeholder="Ast tester"
                          v-model="ast_name"
                          required
                        />
                        <div class="invalid-feedback">
                          Please enter a valid ast name.
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="git_url" class="form-label"
                          >Repository</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="git_url"
                          placeholder="Add a valid GitHub/Bitbucket repository"
                          v-model="git_url"
                          required
                        />
                        <div class="invalid-feedback">
                          Please select a valid repository path.
                        </div>
                      </div>
                      <div class="row b-3">
                        <div class="col-md mt-3 align-self-center">
                      <input type="checkbox" id="check_code_static" v-model="check_code_static">
                      <label for="checkbox">Check code static</label>
                    </div>
                    <div class="col-md mt-3 align-self-center">
                      <input type="checkbox" id="check_vulnerable_deps" v-model="check_vulnerable_deps">
                      <label for="checkbox">Check vulnerable deps</label>
                    </div>
                  </div>
                      <hr class="mt-0 mb-3" />

                      <div class="row mt-3 ms-1 me-1">
                        <div
                          class="alert alert-danger text-white shadow-lg opacity-5"
                          role="alert"
                          v-show="error"
                        >
                         <!--<strong>Error occurred: </strong>{{ message }}--> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  ref="Close"
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  class="btn btn-primary"
                  type="submit"
                  @click="createAst"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </template>
  
  <script>
  import axios from "axios";
  import logo from "@/assets/img/logos/aiaguard.png";
  export default {
    name: "NewAstCard",
  
    created() {
      axios.defaults.baseURL = this.$store.state.backendURL;
      axios.defaults.withCredentials = true;
    },
  
    methods: {
      createAst() {
        console.log("Launch processing", {
              ast_name: this.ast_name,
              git_url: this.git_url,
              check_vulnerable_deps:true,// this.check_vulnerable_deps,  //this.check_vulnerable_deps,
              check_code_static: true, //this.check_code_static, //this.check_code_static,
              vulnerable_deps_engine: "pipaudit",//this.vulnerable_deps_engine,
              code_static_engine: "bandit", //this.code_static_engine
            })
        let all_valid = this.validate();
        console.log("all valid launching")
        if (all_valid) {
          axios
            .post("/analysis/run/ast", {
              ast_name: this.ast_name,
              git_url: this.git_url,
              check_vulnerable_deps:this.check_vulnerable_deps,// this.check_vulnerable_deps,  //this.check_vulnerable_deps,
              check_code_static: this.check_code_static, //this.check_code_static, //this.check_code_static,
              vulnerable_deps_engine: "pipaudit",//this.vulnerable_deps_engine,
              code_static_engine: "bandit", //this.code_static_engine
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then(() => {
              this.error = false;
              this.$refs.Close.click();
              this.$parent.loadAst();
            })
            .catch((error) => {
              console.log(error);
              this.error = true;
              //this.message = error.response.statusText;
            });
        } else {
          this.error = false;
        }
      },
  
      validate() {
        
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.querySelectorAll(".needs-validation");
        let all_valid = true;
        
        // Loop over them and prevent submission
        Array.from(forms).forEach((form) => {
         
              if (!form.checkValidity()) {
                all_valid = false;
              }
  
              form.classList.add("was-validated");
            
        });
        
        return all_valid;
      },
    },
  
    data() {
      return {
        error: false,
        error_message: "",
        is_advanced_metrics: false,
        is_random_seed: false,
        logo,
        dataset: "",
        model: "",
        model_options: [],
        git_url: "",
        check_vulnerable_deps:false,  //this.check_vulnerable_deps,
        check_code_static: true,
        vulnerable_deps_engine: "",
        code_static_engine: "",
        ast_name: ""
      };
    },
  
    computed: {
      getDatasetUrl() {
        return this.dataset_options.find((option) => option.id === this.dataset)
          .url;
      },
      getModelUrl() {
        return this.model_options.find((option) => option.id === this.model).url;
      },
    },
  
    watch: {
      dataset: function () {
        this.model_options = [];
        if (this.dataset > 0) {
          this.model_options = this.model_pairs[this.dataset];
        }
      },
    },
  };
  </script>
  